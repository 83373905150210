import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import {
  renderFormInputRow,
  renderFormInputRowName,
  renderFooterSubmitCancelButtons,
  renderRadioGroup,
  renderSelectAccountDropdown,
  DeleteConfirmation,
  renderFormDateField,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook

const BuildUmbrellaLifeInsuranceComponentV3 = (props) => {
  const {
    state,
    concatContent,
    memberOptions,
    setLastStepInParent,
    isFormValid,
    formErrors,
    expandedPanel,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleInsurance',
    mainTab: 'umbrella',
    subTab: 'umbrellaIns',
    countVal: 'numUmbrellaIns',
  };

  const { formDetails, isEditable, isAddNew } = state;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  return (
    <Typography>
      <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName,
          openDeleteConfirmation,
          props,
          state,
          moduleObject
        )}
      </div>

      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          <div className="row align-items-center">
            <div className="col-lg-9 col-sm-9 col-8 insured_content_outer labelStyle">
              <p className={'radio-group-label required'}>Insured person</p>
            </div>
            <div className="col-lg-3 col-sm-3 col-4 insured_radio_group">
              <input
                name="Horizon Rental Expense"
                className="planner-input"
                disabled={true}
                value={'family'}
                type="text"
              />
            </div>{' '}
          </div>

          <div className="insured_main_outer"
            style={{ display: isEditable && !isAddNew ? 'block' : 'none' }}
          >
            {renderFormInputRowName(
              'text',
              'tlName',
              'name',
              'Short name of policy',
              'text',
              state,
              moduleObject,
              props,
              finalExistingItems,
              true,
              formErrors,
              isAddNew,
              isEditable
            )}

          </div>
          {renderFormInputRow(
            'text',
            'tlCoverage',
            'coverage',
            'Liability Coverage Amount',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors,
            isAddNew,
            isEditable
          )}
          {renderFormDateField(
            'coverageStartYr',
            'Year when coverage started or expected to start',
            'number',
            moduleObject,
            state,
            props,
            'year',
            true,
            false,
            [],
            isAddNew,
            isEditable
          )}
          {renderFormInputRow(
            'text',
            'tlPremium',
            'premium',
            'Annual Premium',
            'currency',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            true, // isMandatory
            formErrors,
            isAddNew,
            isEditable
          )}
          {renderFormInputRow(
            'text',
            'tlPremiumGrowth',
            'premiumGrowth',
            'Annual growth of premium',
            'percentage',
            moduleObject,
            state,
            props,
            undefined,
            !isEditable, //isDisabled,
            false, // isMandatory
            formErrors,
            isAddNew,
            isEditable
          )}

          {isAddNew && (
            <div className="insured_main_outer">
              {renderFormInputRowName(
                'text',
                'tlName',
                'name',
                'Short name of policy',
                'text',
                state,
                moduleObject,
                props,
                finalExistingItems,
                true,
                formErrors,
                isAddNew,
                isEditable
              )}
            </div>
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </Typography>
  );
};

export default BuildUmbrellaLifeInsuranceComponentV3;
