import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import { isEqual } from 'lodash';

import {
  renderFooterSubmitCancelButtons,
  renderSelectAccountDropdown,
  DeleteConfirmation,
  renderFormInputRowName,
  renderFormInputRow,
  renderFormSelectOption,
  renderFormDateField,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook
import { secondHomesFormFields } from '../../constants/Client';
import { PropertyNamesMap } from '../../constants/PropertyNames';
import Controls from '../controls/Controls';
import {
  generateProps,
  percentageConversionToUI,
  formatValueByType,
  formatValuesForUI,
  validateDateFields,
  createLocalDateFromDateString,
  parseCurrency,
  getDateValue,
} from '../../helpers/SolutionsHelper';
import { planDetailsByPlanIdSelector } from '../../selectors/plannerDetailsSelector';
import { useSelector } from 'react-redux';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { cloneDeep } from 'lodash';

const BuildPropertiesSecondHomes = forwardRef((props, ref) => {
  const {
    state,
    concatContent,
    taxStateOptions,
    setLastStepInParent,
    setFormDetails,
    formErrors,
    isFormValid,
  } = props;

  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );

  const moduleObject = {
    moduleTab: 'moduleInvestments',
    mainTab: 'secondHomes',
    subTab: 'props',
    countVal: 'numSecondHomes',
  };

  const { formDetails, isEditable, isAddNew, isCancelled } = state;
  // const [isFormValid, setIsFormValid] = useState(true);

  const [modalType, setModalType] = useState(undefined);

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  const validateSecondHomeNames = useCallback(
    (value) => {
      const { moduleInvestments: { secondHomes: { props = [] } = {} } = {} } =
        planDetailsByPlanId || {};
      const existingSecondHome = props.find(
        (secondHome) => secondHome?.name.toLowerCase() === value.toLowerCase()
      );
      if (modalType === 'add' && existingSecondHome) {
        return false;
      }
      return true;
    },
    [modalType, planDetailsByPlanId]
  );

  return (
    <>
      <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName,
          openDeleteConfirmation,
          props,
          state,
          moduleObject
        )}
      </div>
      {(isAddNew || isEditable) && (
        <div className="insured_main_outer">
          {renderFormInputRowName(
            'text',
            'tlName',
            'name',
            'Short name of home',
            'text',
            state,
            moduleObject,
            props,
            finalExistingItems,
            true, //isMandatory field
            formErrors,
            isAddNew
          )}
        </div>
      )}

      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          {renderFormDateField(
            'purchaseDate',
            'Purchase date',
            'text',
            moduleObject,
            state,
            props,
            'date'
          )}
          {renderFormInputRow(
            'text',
            'tlPurchasePrice',
            'purchasePrice',
            'Purchase price',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlValue',
            'value',
            'Current value',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlAppreciationRate',
            'appreciationRate',
            'Appreciation rate',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlAggMaintenanceExp',
            'aggMaintenanceExp',
            'Maintenance expense',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlAggMaintenanceExpGrowth',
            'aggMaintenanceExpGrowth',
            'Expense growth',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormDateField(
            'origOrRefiLoanDate',
            'Orig/refi loan dt',
            'text',
            moduleObject,
            state,
            props,
            'date'
          )}
          {renderFormInputRow(
            'text',
            'tlOrigOrRefiLoanAmount',
            'origOrRefiLoanAmount',
            'Loan amount',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlLoanAmountOutstanding',
            'loanAmountOutstanding',
            'Amount outstanding',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlLoanTerm',
            'loanTerm',
            'Loan term years',
            'number',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlTaxRate',
            'fixedIntRate',
            'Fixed rate',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormSelectOption(
            'text',
            'tlPropDomicile',
            'propDomicile',
            'Domicile state',
            taxStateOptions,
            moduleObject,
            state,
            props,
            true //isMandatory field
          )}
          {renderFormInputRow(
            'text',
            'tlTaxRate',
            'taxRate',
            'Property tax rate',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlTaxBasis',
            'taxBasis',
            'Tax basis',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlTaxBasisGrowth',
            'taxBasisGrowth',
            'Basis growth',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}

      {/* <div className="signup-body-container">
        <div className="insured_main_outer">
          {Object.keys(selectedSecondHome).length > 0 &&
            secondaryHomesFormFields.map((field) => {
              switch (field.type) {
                case 'text':
                  return (
                    <div className="row align-items-center">
                      <div
                        className={`col-lg-8 insured_content_outer labelStyle 
          }`}
                      >
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 insured_radio_group">
                        <input
                          type={field.type}
                          id={field.key}
                          name={field.key}
                          disabled={
                            !modalType ||
                            (modalType === 'edit' && field.isDisabled)
                          }
                          value={selectedSecondHome[field.key]}
                          className={'custom-input'} // Apply the appropriate class
                          onChange={(event) => handleOnChange(event, field.key)}
                          onBlur={() => handleBlur(field.key)}
                          placeholder=""
                        />
                      </div>
                      {conditionalFields.includes(field.key) &&
                        selectedSecondHome &&
                        Object.keys(selectedSecondHome).length > 0 &&
                        !getIsValidFieldValue(field.key) && (
                          <p className="error-text">
                            {selectedSecondHome[field.key] !== '' &&
                            selectedSecondHome[field.key] !== undefined
                              ? field.errorMessage
                              : ''}
                          </p>
                        )}
                    </div>
                  );
                case 'select':
                  return (
                    <div className="row align-items-center">
                      <div className={`col-lg-8 insured_content_outer`}>
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className={`col-lg-3 insured_radio_group`}>
                        <Controls.Select
                          name={field.key}
                          id={field.key}
                          value={selectedSecondHome[field.key]?.toLowerCase()}
                          onChange={(event, index) =>
                            handleOnChange(event, field.key)
                          }
                          disabled={
                            !modalType ||
                            (modalType === 'edit' && field.isDisabled)
                          }
                          className={'custom-input'}
                          options={field.options.map((c) => {
                            return {
                              value: c.value?.toLowerCase(),
                              label: c.label,
                            };
                          })}
                        />
                      </div>
                    </div>
                  );
                case 'date':
                  const dateValue = getDateValue(
                    selectedSecondHome[field.key],
                    field
                  );
                  return (
                    <div className="row align-items-center">
                      <div className="col-lg-8 insured_content_outer">
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 insured_radio_group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            views={[field.dataType]}
                            className="planner-input long-text-input"
                            format={
                              field.dataType === 'year' ? 'yyyy' : 'yyyy-MM-dd'
                            }
                            autoOk
                            variant="inline"
                            InputAdornmentProps={{ position: 'start' }}
                            value={dateValue}
                            onChange={(date) => handleDateChange(date, field)}
                            onBlur={() => handleBlur(field.key)}
                            inputProps={{ style: { height: '39px' } }}
                            minDate={
                              (modalType === 'add' ||
                                (modalType === 'edit' &&
                                  new Date(dateValue).setHours(0, 0, 0, 0) >=
                                    new Date().setHours(0, 0, 0, 0))) &&
                              field.key === 'origOrRefiLoanDate'
                                ? getDateValue(
                                    selectedSecondHome['purchaseDate']
                                  )
                                : new Date()
                            }
                            // disabled={!modalType ||
                            //   (modalType === 'edit' &&
                            //     new Date(dateValue).setHours(0, 0, 0, 0) <
                            //     new Date().setHours(0, 0, 0, 0))
                            // }
                            disabled={!modalType}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      </div> */}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  );
});
export default BuildPropertiesSecondHomes;
