import React, { useState, useEffect } from 'react';
import Controls from './controls/Controls';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { umbrellaLifeInsuranceCols } from '../constants/dashboard';
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material'; // Import dialog components
import '../css/InsuranceContent.css';
import edit_pencil from '../images/edit_pencil.svg';
import pdf_page_plus_icon from '../images/pdf_page_plus_icon.svg';
import delete_icon from '../images/delete_icon.svg';

export const UmbrellaLifeContent = (props) => {
  const {
    umbrellaInsuranceProducts,
    state,
    concatInsurances,
    transformValue,
    handleFormFieldChangeInsurance,
    handleBlur,
    onAccountChange,
    setSelectionModel,
    rowClick,
    handleEditInsurance,
    handleAddToPortfolio,
    handleDeleteInsurance,
    handleCancelInsurance,
    setLastStepInParent,
  } = props;

  const {
    formDetails,
    selectionModel,
    isEditable,
    isAddNew,
    isFormValid,
    memberOptions,
    formErrors,
  } = state;

  const [wlTitle, setwlTitle] = useState('');
  const finalExistingInsurances = concatInsurances('umbrellaIns');
  const defaultName =
    (formDetails &&
      formDetails['umbrellaIns'] &&
      formDetails['umbrellaIns'].name) ||
    '';

  const [nameValidationError, setNameValidationError] = useState('');
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [nameToDelete, setNameToDelete] = useState('');

  const openDeleteConfirmation = (name) => {
    setIsDeleteConfirmationOpen(true);
    setNameToDelete(name);
  };

  // Function to close the confirmation dialog
  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  // Function to handle item deletion after confirmation
  const handleDeleteConfirmation = () => {
    closeDeleteConfirmation();
    // Perform the delete action here
    handleDeleteInsurance(
      'umbrellaIns',
      'umbrella',
      'numUmbrellaIns',
      nameToDelete
    );
  };

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  useEffect(() => {
    const defaultSelectedAccountInfo = finalExistingInsurances.find(
      (account) => account.id === defaultName
    );

    if (defaultSelectedAccountInfo) {
      setwlTitle(defaultSelectedAccountInfo.title);
    }
  }, [defaultName, finalExistingInsurances]);

  const validateNameField = () => {
    if (!formDetails['umbrellaIns'] || !formDetails['umbrellaIns'].name) {
      setNameValidationError('Short name of policy is required');
      return false;
    }

    const enteredName = formDetails['umbrellaIns'].name.toLowerCase();

    if (isAddNew) {
      // Check for duplicate name only when adding a new item
      const isDuplicateName = finalExistingInsurances.some(
        (ins) => ins.title.toLowerCase() === enteredName
      );

      if (isDuplicateName) {
        setNameValidationError('Name must be unique');
        return false;
      }
    }

    setNameValidationError('');
    return true;
  };

  const handleSubmit = () => {
    if (validateNameField()) {
      handleAddToPortfolio('umbrellaIns', 'umbrella');
    }
  };

  const renderRadioGroup = () => (
    <div className="col-lg-4 insured_radio_group">
      <Controls.RadioGroup
        name="housing-expense-radio-buttons"
        value={
          (formDetails &&
            formDetails['umbrellaIns'] &&
            formDetails['umbrellaIns'].insured) ||
          false
        }
        rowType={true}
        onChange={(event) =>
          handleFormFieldChangeInsurance(
            event,
            'insured',
            'radio',
            'umbrellaIns'
          )
        }
        items={memberOptions}
        disabled={state.isEditable}
      ></Controls.RadioGroup>
    </div>
  );

  const renderInputRow = (
    type,
    idName,
    name,
    label,
    valueType,
    isDisabled = false,
    isMandatory = false,
    formErrors = []
  ) => {
    const isFieldInValid = formErrors.filter(
      (v) => v.apiFieldName.toLowerCase() === name.toLowerCase() && !v.isValid
    )[0];
    return (
      <div
        className={`row align-items-center mb-3 ${
          isEditable && isAddNew ? 'editable-row' : ''
        }`}
      >
        <div className="col-lg-8 insured_content_outer">
          <p className={`${isMandatory ? 'required' : ''}`}>{label}</p>
        </div>
        <div className="col-lg-3 insured_radio_group">
          <input
            type={type}
            id={idName}
            name={idName}
            value={transformValue(formDetails['umbrellaIns'][name], valueType)}
            disabled={isDisabled}
            onChange={(event) =>
              handleFormFieldChangeInsurance(
                event,
                name,
                'input',
                'umbrellaIns'
              )
            }
            onBlur={(event) =>
              handleBlur(event, name, 'input', 'umbrellaIns', valueType)
            }
            placeholder=""
          />
          {/* {name === 'name' && nameValidationError && (
            <span className="validation-error">{nameValidationError}</span>
          )} */}
        </div>
        {isFieldInValid && (
          <p className="error-text">{isFieldInValid.validationMessage}</p>
        )}
      </div>
    );
  };

  const renderChooseProduct = () => {
    return (
      <div
        className="choose_a_product_main_outer"
        style={{ display: isEditable ? 'block' : 'none' }}
      >
        <h5>Choose a product from below list:</h5>
        <div className="choose_a_product_table">
          <Box sx={{ height: 150, width: '100%' }}>
            <DataGrid
              rows={
                umbrellaInsuranceProducts &&
                umbrellaInsuranceProducts.length > 0
                  ? umbrellaInsuranceProducts.map((m) => ({
                      ...m,
                      premium:
                        (m.premium && transformValue(m.premium, 'currency')) ||
                        0,
                      coverage:
                        (m.coverage &&
                          transformValue(m.coverage, 'currency')) ||
                        0,
                      cashValueAnnual:
                        (m.cashValueAnnual &&
                          transformValue(m.cashValueAnnual, 'currency')) ||
                        0,
                    }))
                  : []
              }
              autoHeight
              rowHeight={25}
              headerHeight={40}
              columns={umbrellaLifeInsuranceCols}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel, 'umbrellaIns');
              }}
              selectionModel={
                selectionModel['umbrellaIns']
                  ? selectionModel['umbrellaIns']
                  : []
              }
              pageSize={10}
              onRowClick={(params, event, details) =>
                rowClick(params, event, details, 'umbrellaIns')
              }
              rowsPerPageOptions={[10]}
              className="data-grid"
              disableColumnMenu={true}
              hideFooter={true}
            />
          </Box>
        </div>
      </div>
    );
  };

  const renderSelectAccountDropdown = () => {
    return (
      <div className="row align-items-center">
        <div className="col-lg-3 col-md-4 col-6">
          <Controls.Select
            className="js-example-basic-single"
            name="Account"
            id="Account"
            value={defaultName}
            onChange={(e) =>
              onAccountChange(e, finalExistingInsurances, 'umbrellaIns')
            }
            style={{ display: !state.isEditable ? 'block' : 'none' }}
            options={finalExistingInsurances.map((c) => {
              return {
                value: c.id,
                label: c.title,
                key: c.id,
              };
            })}
          />
        </div>
        <div
          className="col-lg-8 col-md-8 col-5 button-group"
          style={{
            display: !isEditable ? 'block' : 'none',
            width: 'max-content',
          }}
        >
          {finalExistingInsurances && finalExistingInsurances.length > 0 && (
            <button
              className="action-button"
              onClick={(e) => {
                setNameValidationError('');
                handleEditInsurance(e, state.solutionsSubTab);
              }}
            >
              <img src={edit_pencil} alt="" title="" />
            </button>
          )}
          {finalExistingInsurances && finalExistingInsurances.length > 0 && (
            <button
              className="action-button"
              onClick={(e) => openDeleteConfirmation(defaultName)}
            >
              <img src={delete_icon} alt="" title="" />
            </button>
          )}
          <button
            className="action-button"
            onClick={(event) => {
              setNameValidationError('');
              handleEditInsurance(event, state.solutionsSubTab, true);
              handleFormFieldChangeInsurance(
                event,
                'insured',
                'input',
                'umbrellaIns',
                true
              );
            }}
          >
            <img src={pdf_page_plus_icon} alt="" title="" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="insured_main_outer">
        {/* {!isAddNew && !isEditable ? ( */}
        <div className="row">
          <div className="col-lg-8 insured_content_outer">
            <p className={'radio-group-label required'}>Insured person</p>
          </div>
          <div className="col-lg-3 insured_radio_group">
            <input
              name="Horizon Rental Expense"
              className="planner-input"
              disabled={true}
              value={'family'}
              type="text"
            />
          </div>{' '}
        </div>
      </div>
      {/* // ) : (
        //   <>
        //     {renderInputRow(
        //       'text',
        //       'ulInsured',
        //       'insured',
        //       'Insured',
        //       'textRadioButton',
        //       isEditable,
        //       true, //isMandatory
        //       formErrors
        //     )}
        //   </>
        // )}
      // </div> */}
      <div className="insured_main_outer"
                style={{ display: isEditable && !isAddNew ? 'block' : 'none' }}
              >
                
              </div>
      {(!isAddNew && isEditable) && (
        <div className="insured_main_outer">
          {renderInputRow(
            'text',
            'ulName',
            'name',
            'Short name of policy',
            'text',
            !isAddNew,
            true, //isMandatory
            formErrors
          )}
        </div>
      )
    }
      {!isEditable && (
        <div className="Select_account_main_outer">
          {renderSelectAccountDropdown()}
        </div>
      )}

      {/* <div
        className="insured_main_outer"
        style={{ display: isEditable && !isAddNew ? 'block' : 'none' }}
      >
        {renderInputRow(
          'text',
          'ulName',
          'name',
          'Short name of policy',
          'text',
          isEditable,
          true
        )}
      </div> */}
      {renderChooseProduct()}
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'ulCoverage',
          'coverage',
          'Liability Coverage Amount',
          'currency',
          !isEditable,
          true, //isMandatory
          formErrors
        )}

        <div className="insured_main_outer" style={{ marginBottom: 10 }}>
          <div className="row align-items-center">
            <div className="col-lg-8 insured_content_outer">
              <p>Year when coverage started or expected to start</p>
            </div>
            <div className="col-lg-3 insured_radio_group">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  views={['year']}
                  className="planner-input long-text-input"
                  autoOk
                  variant="inline"
                  InputAdornmentProps={{ position: 'start' }}
                  value={
                    formDetails &&
                    formDetails['umbrellaIns'] &&
                    formDetails['umbrellaIns'].coverageStartYr
                  }
                  disabled={!isEditable}
                  onChange={(event) =>
                    handleFormFieldChangeInsurance(
                      event,
                      'coverageStartYr',
                      'datepicker',
                      'umbrellaIns'
                    )
                  }
                  inputProps={{ style: { height: 'auto' } }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>

        {renderInputRow(
          'text',
          'ulPremium',
          'premium',
          'Annual Premium',
          'currency',
          !isEditable,
          true, //isMandatory
          formErrors
        )}
        {renderInputRow(
          'text',
          'ulPremiumGrowth',
          'premiumGrowth',
          'Annual growth of premium',
          'percentage',
          !isEditable,
          true, //isMandatory
          formErrors
        )}
      </div>

      {/* {isAddNew && (
        <div className="insured_main_outer">
          {renderInputRow(
            'text',
            'tlAddName',
            'name',
            'Short name of policy',
            'text',
            false, //isDisabled
            true, //isMandatory
            formErrors
          )}
        </div>
      )} */}

      {isAddNew ? (
        <div className="insured_main_outer">
          {renderInputRow(
            'text',
            'ulName',
            'name',
            'Short name of policy',
            'text',
            !isAddNew,
            true, //isMandatory
            formErrors
          )}
        </div>
      ) :
      (
        <></>
      )
      }

      {isEditable ? (
        <div className="Simulate_button_group">
          <button
            className={
              !(isEditable || isAddNew)
                ? 'common-button secondary-button'
                : 'common-button'
            }
            onClick={(event) => {
              handleCancelInsurance('umbrellaIns', 'insured');
            }}
            disabled={!(isEditable || isAddNew)}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className={
              !isFormValid || !(isEditable || isAddNew)
                ? 'common-button secondary-button'
                : 'common-button'
            }
            disabled={!isFormValid || !(isEditable || isAddNew)}
          >
            Update
          </button>
        </div>
      ) : (
        <></>
      )}
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmation}
        className="delete_popup_outer"
      >
        <DialogTitle>
          Are you sure you want to delete "{nameToDelete}"?
        </DialogTitle>
        <DialogActions>
          <button onClick={closeDeleteConfirmation} className="common-button">
            Cancel
          </button>
          <button onClick={handleDeleteConfirmation} className="common-button">
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UmbrellaLifeContent;
