import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SolutionsJourneyButtons from './shared/SolutionsJourneyButtons';
import { Grid } from '@material-ui/core';
import Controls from './controls/Controls';
import { isEqual } from 'lodash';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const radioOptions = [
  { title: 'Yes', id: true },
  { title: 'No', id: false },
];

const percentageFields = [
  'horizonRentalExpense',
  'horizonFactorPercent',
  'retirementFactor1',
  'retirementFactor2',
  'retirementFactor3',
  'retirementRentalExpense',
  'horPercentValue',
  'retPercentValue',
  'affordabilityFactor',
];

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fiCard: {
    height: 400,
  },
  media: {
    height: 50,
  },
});

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
export default function SolutionsJourney(props) {
  const {
    updateExpenseDetailsSolutionsInRedux,
    storeValues,
    passIsLastStepInfoToParent
    // setLastStepInParent,
  } = props;
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isBlurCalled, setIsBlurCalled] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [lifestyle, setLifestyle] = useState('');
  const [fieldValueChanged, setFieldValueChanged] = useState(false);
  const [stepName, setStepName] = useState({
    label: 'Affordability Factor',
    value: 'affordabilityFactor',
  });
  const [isFormValid, setIsFormValid] = useState(true);
  const prevFormDetails = usePrevious(formDetails);
  const prevIsLastStep = usePrevious(isLastStep);

  const transformValue = (val) => {
    let fieldValue = val;
    if (val !== null && val !== undefined && val !== '') {
      if (val.toString().includes('%')) {
        const percentFieldValue = val.split('%')[0];
        fieldValue =
          percentFieldValue !== '' && !isNaN(percentFieldValue)
            ? parseFloat(percentFieldValue) / 100
            : 0;
      }
      fieldValue = parseFloat(parseFloat(fieldValue) * 100).toFixed(2);
      return `${fieldValue}%`;
    }
    return `0.00%`;
  };

  const updateStepName = useCallback((stepNo) => {
    let tempStepName = {};
    switch (stepNo) {
      case 1:
        tempStepName = { label: 'Horizon', value: 'horizon' };
        break;
      case 2:
        tempStepName = { label: 'Retirement', value: 'retirement' };
        break;
      case 3:
        tempStepName = { label: 'Groceries & Staples', value: 'food' };
        break;
      case 4:
        tempStepName = {
          label: 'Phones & Entertainment',
          value: 'phonesAndEnt',
        };
        break;
      case 5:
        tempStepName = { label: 'Travel', value: 'travel' };
        break;
      case 6:
        tempStepName = { label: 'Restaurants', value: 'restaurants' };
        break;
      case 7:
        tempStepName = {
          label: 'Personal / Pet / Other care',
          value: 'insurancesNonProp',
        };
        break;
      case 8:
        tempStepName = {
          label: 'Health & Fitness (excluding what is included in Insurances)',
          value: 'healthAndFitness',
        };
        break;
      case 9:
        tempStepName = { label: 'Utilities', value: 'utils' };
        break;
      case 10:
        tempStepName = {
          label:
            'Transportation (excluding what is included in Vehicles and Insurances)',
          value: 'cars',
        };
        break;
      case 11:
        tempStepName = {
          label: 'Shopping & Miscellaneous',
          value: 'shoppingAndMisc',
        };
        break;
      default:
        tempStepName = {
          label: 'Affordability Factor',
          value: 'affordabilityFactor',
        };
        break;
    }
    return tempStepName;
  }, []);

  useEffect(() => {
    const tempStepName = updateStepName(currentStep);
    if (!isEqual(tempStepName, stepName)) {
      setStepName(tempStepName);
    }
    if (isLastStep && prevIsLastStep !== isLastStep) {
      passIsLastStepInfoToParent(true);
    } else if (!isLastStep && prevIsLastStep !== isLastStep) {
      passIsLastStepInfoToParent(false);
    }
  }, [
    currentStep,
    passIsLastStepInfoToParent,
    updateStepName,
    stepName,
    isLastStep,
    prevIsLastStep,
  ]);

  const handleNext = useCallback(
    (data) => {
      if (!isLastStep) {
        const stepNo = currentStep === 11 ? 11 : currentStep + 1;
        if (
          stepNo === 11 ||
          (storeValues.useAggNonHousingExpense && stepNo === 2) ||
          (formDetails.discSameFactorAcross && stepNo === 2)
        ) {
          setIsLastStep(true);
        }
        setCurrentStep(stepNo);
      }
      updateExpenseDetailsSolutionsInRedux(data, stepName.value, isLastStep);
      setIsBlurCalled(false);
    },
    [
      updateExpenseDetailsSolutionsInRedux,
      currentStep,
      isLastStep,
      stepName,
      storeValues.useAggNonHousingExpense,
      formDetails.discSameFactorAcross,
    ]
  );

  const handlePrevious = useCallback(
    (data) => {
      const stepNo = currentStep < 1 ? 1 : currentStep - 1;
      setCurrentStep(stepNo);
      setIsLastStep(false);
      updateExpenseDetailsSolutionsInRedux(data, stepName.value);
      setIsBlurCalled(false);
    },
    [updateExpenseDetailsSolutionsInRedux, currentStep, stepName]
  );

  const onReduxUpdate = useCallback(
    (from, newFormDetails) => {
      const tempFormDetails = newFormDetails ? newFormDetails : formDetails;
      const tempFormDetailsArray = Object.entries(tempFormDetails).map((m) => {
        return { name: m[0], value: convertBackToApiType(m[1]) };
      });
      const tempFormDetailsObject = tempFormDetailsArray.reduce(
        (previousObject, currentObject) => {
          return Object.assign(previousObject, {
            [currentObject.name]: currentObject.value,
          });
        },
        {}
      );
      from === 'next'
        ? handleNext(tempFormDetailsObject)
        : from === 'previous'
          ? handlePrevious(tempFormDetailsObject)
          : updateExpenseDetailsSolutionsInRedux(
            tempFormDetailsObject,
            stepName.value,
            isLastStep
          );
      setIsBlurCalled(false);
    },
    [
      formDetails,
      handleNext,
      handlePrevious,
      updateExpenseDetailsSolutionsInRedux,
      stepName,
      isLastStep,
    ]
  );

  useEffect(() => {
    if (
      !isEqual(prevFormDetails, formDetails) &&
      Object.keys(formDetails).length > 0 &&
      fieldValueChanged &&
      isBlurCalled
    ) {
      onReduxUpdate();
      setFieldValueChanged(false);
    }
  }, [
    prevFormDetails,
    formDetails,
    onReduxUpdate,
    fieldValueChanged,
    isBlurCalled,
  ]);

  useEffect(() => {
    if (
      storeValues &&
      Object.keys(storeValues).length > 0 &&
      !fieldValueChanged &&
      !isBlurCalled
    ) {
      const tempFormDetails = {
        affordabilityFactor:
          (storeValues.affordabilityFactor &&
            transformValue(storeValues.affordabilityFactor)) ||
          '100.00%',
        horizonRentalExpense:
          (storeValues.horFactor[`horizonrental`] &&
            transformValue(storeValues.horFactor[`horizonrental`])) ||
          '100.00%',
        horizonFactorPercent:
          (storeValues.discHorFactor &&
            transformValue(storeValues.discHorFactor)) ||
          '100.00%',
        retirementFactor1:
          (storeValues.discRetFactor1 &&
            transformValue(storeValues.discRetFactor1)) ||
          '100.00%',
        retirementFactor2:
          (storeValues.discRetFactor2 &&
            transformValue(storeValues.discRetFactor2)) ||
          '100.00%',
        retirementFactor3:
          (storeValues.discRetFactor3 &&
            transformValue(storeValues.discRetFactor3)) ||
          '100.00%',
        retirementRentalExpense:
          (storeValues.retFactor['retirementrental'] &&
            transformValue(storeValues.retFactor['retirementrental'])) ||
          '100.00%',
        discSameFactorAcross:
          stepName.value === 'retirement' ||
            stepName.value === 'horizon' ||
            stepName.value === 'affordabilityFactor'
            ? formDetails.discSameFactorAcross !== undefined &&
              formDetails.discSameFactorAcross !==
              storeValues.useDiscretionaryFlag
              ? formDetails.discSameFactorAcross
              : storeValues.useDiscretionaryFlag
            : formDetails.discSameFactorAcross,
        horPercentValue:
          stepName.value !== 'retirement' && stepName.value !== 'horizon'
            ? (storeValues.horFactor[`horizon${[stepName.value]}`] &&
              transformValue(
                storeValues.horFactor[`horizon${[stepName.value]}`]
              )) ||
            '100.00%'
            : '100.00%',
        retPercentValue:
          stepName.value !== 'retirement' && stepName.value !== 'horizon'
            ? (storeValues.retFactor[`retirement${[stepName.value]}`] &&
              transformValue(
                storeValues.retFactor[`retirement${[stepName.value]}`]
              )) ||
            '100.00%'
            : '100.00%',
        disc: {
          ...storeValues.disc,
        },
      };
      const tempLifestyle =
        (storeValues.lifestyle && storeValues.lifestyle) || 'custom';
      setLifestyle(tempLifestyle);
      setFormDetails(tempFormDetails);
    }
  }, [
    storeValues,
    stepName,
    formDetails?.discSameFactorAcross,
    fieldValueChanged,
    isBlurCalled,
  ]);

  const convertBackToApiType = (value) => {
    let convertedValue =
      typeof value !== 'boolean'
        ? value.toString().includes('%')
          ? value.split('%')[0]
          : value.toString().includes('$')
            ? parseFloat(value.split('$')[1].replace(/,/g, ''))
            : value !== ''
              ? value
              : ''
        : value;
    const newFieldValue =
      typeof value !== 'boolean' && value.toString().includes('%')
        ? convertedValue !== '' &&
        !isNaN(convertedValue) &&
        parseFloat(convertedValue) / 100
        : convertedValue;
    return newFieldValue;
  };

  useEffect(() => {
    if (isLastStep && isBlurCalled && !isEqual(prevFormDetails, formDetails)) {
      onReduxUpdate('next');
    }
  }, [onReduxUpdate, isLastStep, formDetails, prevFormDetails, isBlurCalled]);

  const handleDiscFormFieldChange = (e, from) => {
    let fieldValue =
      e.target.checked && e.target.defaultValue === 'true' ? true : false;
    setIsBlurCalled(true);
    setFieldValueChanged(true);
    const newFormDetails = {
      ...formDetails,
      disc: {
        ...formDetails.disc,
        [from]: fieldValue,
      },
    };
    setFormDetails(newFormDetails);
  };

  const handleFormFieldChange = (e, from, type) => {
    let fieldValue =
      e && type === 'radio'
        ? e.target.checked && e.target.defaultValue === 'true'
          ? true
          : false
        : e.target.value;
    if (type === 'percent') {
      if (
        fieldValue !== null &&
        fieldValue !== undefined &&
        fieldValue !== ''
      ) {
        if (fieldValue.toString().includes('%')) {
          let percentFieldValue = fieldValue.split('%')[0];
          if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
            fieldValue = percentFieldValue;
          }
        }
      }
    } else if (type === 'currency') {
      if (
        fieldValue !== null &&
        fieldValue !== undefined &&
        fieldValue !== ''
      ) {
        if (fieldValue.toString().includes('$')) {
          let currencyFieldValue = fieldValue.split('$')[1];
          currencyFieldValue = currencyFieldValue.replace(/,/g, '');
          if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
            fieldValue = currencyFieldValue;
          }
        }
      }
    } else if (type === 'number') {
      if (
        fieldValue !== '' &&
        fieldValue !== null &&
        fieldValue !== undefined
      ) {
        if (isNaN(fieldValue)) {
          fieldValue = 0;
        }
      }
    }
    if (type === 'radio') {
      setIsBlurCalled(true);
    } else {
      setIsBlurCalled(false);
    }
    if (type === 'radio') {
      if (fieldValue) {
        setIsLastStep(true);
      } else {
        setIsLastStep(false);
      }
    }
    if (fieldValue === 'NaN') {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
    setFieldValueChanged(true);
    setFormDetails((old) => ({
      ...old,
      [from]: fieldValue,
    }));
  };

  const transformValue1 = (value, field) => {
    if (value) {
      if (percentageFields.includes(field)) {
        let percentFieldValue = '';
        if (isNaN(value) && value.includes('%')) {
          percentFieldValue = parseFloat(
            parseFloat(value.split('%')[0]) % 100
          ).toFixed(1);
        } else {
          percentFieldValue = parseFloat(parseFloat(value) * 100).toFixed(1);
        }
        return `${percentFieldValue}%`;
      } else {
        let numVal = value;
        if (isNaN(numVal) && numVal.includes('$')) {
          numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
        }
        const numFormat = Intl.NumberFormat('en-US');
        const newNumformat = numFormat.format(numVal);
        return `$${newNumformat}`;
      }
    } else if (value === 0) {
      if (percentageFields.includes(field)) {
        return `${0}%`;
      } else {
        return `$${0}`;
      }
    }
  };

  const handleBlur = (event, field) => {
    let { value } = event && event.target;
    let percentFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      if (value.toString().includes('%')) {
        percentFieldValue = value.split('%')[0];
        if (
          percentFieldValue !== '' &&
          !isNaN(percentFieldValue) &&
          Number(percentFieldValue) >= 0
        ) {
          percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
          value = `${percentFieldValue}%`;
        } else {
          value = `${0}%`;
        }
      } else if (!isNaN(value) && Number(value) >= 0) {
        percentFieldValue = parseFloat(value).toFixed(2);
        value = `${percentFieldValue}%`;
      } else {
        value = `${0}%`;
      }
    } else {
      value = `${0}%`;
    }
    setIsBlurCalled(true);
    const newFormDetails = {
      ...formDetails,
      [field]: value,
    };
    setFormDetails(newFormDetails);
  };

  const renderSteps = () => {
    const expenseTypeMsg =
      lifestyle !== 'custom'
        ? `Expenses are based on Lifestyle. For more granularity, you may change it to Aggregate or Itemized in Build`
        : storeValues.useAggNonHousingExpense
          ? `Expenses are based on Aggregate. For more granularity, you may set expenses to Itemized in Build`
          : 'Expenses are based on Itemized';
    return (
      <>
        {lifestyle === 'custom' && (
          <div className="solution_expenses_outer">
            {currentStep === 0 ? (
              <Grid container spacing={2} className="">
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    padding: '5px',
                  }}
                >
                  Adjust Expenses
                </Grid>
                <Grid
                  item
                  xs={8}
                  className=""
                  style={{ marginRight: '15px', fontSize: '14px' }}
                >
                  Affordability Factor
                </Grid>
                <Grid item xs={3}>
                  <input
                    name="AffordabilityFactor"
                    className=""
                    value={formDetails.affordabilityFactor}
                    type="text"
                    onBlur={(event) => handleBlur(event, 'affordabilityFactor')}
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'affordabilityFactor',
                        'percent'
                      )
                    }
                  />
                </Grid>
              </Grid>
            ) : currentStep === 1 ? (
              <Grid container spacing={2} className="">
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    padding: '5px',
                  }}
                >
                  Adjust Expenses during Planning Horizon
                </Grid>
                <Grid
                  item
                  xs={12}
                  className=""
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    padding: '5px',
                  }}
                >
                  You may consider adjusting down your annual expense during
                  your planning horizon if you were to have a budget deficit.
                </Grid>

                <Grid
                  item
                  xs={8}
                  className=""
                  style={{ marginRight: '15px', fontSize: '12px' }}
                >
                  What is the most conservative percent of rental expense you
                  would be willing to consider, if you must reduce? (if you were
                  to liquidate your primary home)
                </Grid>
                <Grid item xs={3}>
                  <input
                    name="Horizon Rental Expense"
                    className=""
                    value={formDetails.horizonRentalExpense}
                    type="text"
                    onBlur={(event) =>
                      handleBlur(event, 'horizonRentalExpense')
                    }
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'horizonRentalExpense',
                        'percent'
                      )
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={8}
                  className=""
                  style={{ marginRight: '15px', fontSize: '12px' }}
                >
                  What is the most conservative percent of discretionary
                  non-housing expense you would be willing to consider, if you
                  must reduce?
                </Grid>

                <Grid item xs={3}>
                  <input
                    name="Horizon Factor Percent"
                    className=""
                    value={formDetails.horizonFactorPercent}
                    type="text"
                    onBlur={(event) =>
                      handleBlur(event, 'horizonFactorPercent')
                    }
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'horizonFactorPercent',
                        'percent'
                      )
                    }
                  />
                </Grid>
              </Grid>
            ) : currentStep === 2 ? (
              <Grid container spacing={2} className="">
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    padding: '5px',
                  }}
                >
                  Adjust Expenses during Retirement
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    padding: '5px',
                  }}
                >
                  You may consider adjusting down your annual expense during
                  your retirement phases: phase-1 is first one-third of
                  retirement, phase-2 is middle one-third of retirement, and
                  phase-3 is last one-third of retirement.
                </Grid>

                <Grid
                  item
                  xs={8}
                  className=""
                  style={{ marginRight: '15px', fontSize: '12px' }}
                >
                  What percent of pre-retirement rental expenses do you expect
                  during phase-1 of retirement? (if you were to liquidate your
                  primary home)
                </Grid>
                <Grid item xs={3}>
                  <input
                    name="Retirement Rental Expense"
                    className=""
                    value={formDetails.retirementRentalExpense}
                    type={'text'}
                    onBlur={(event) =>
                      handleBlur(event, 'retirementRentalExpense')
                    }
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'retirementRentalExpense',
                        'percent'
                      )
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={8}
                  className=""
                  style={{ marginRight: '15px', fontSize: '12px' }}
                >
                  What percent of pre-retirement non-housing discretionary
                  expenses do you expect during phase-1 of retirement?
                </Grid>

                <Grid item xs={3}>
                  <input
                    name="Disc Factor 1"
                    className=""
                    value={formDetails.retirementFactor1}
                    type={'text'}
                    onBlur={(event) => handleBlur(event, 'retirementFactor1')}
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'retirementFactor1',
                        'percent'
                      )
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={8}
                  className=""
                  style={{ marginRight: '15px', fontSize: '12px' }}
                >
                  What percent of phase-1 expenses do you expect during phase-2
                  of retirement?
                </Grid>
                <Grid item xs={3}>
                  <input
                    name="Disc Factor 2"
                    className=""
                    value={formDetails.retirementFactor2}
                    type={'text'}
                    onBlur={(event) => handleBlur(event, 'retirementFactor2')}
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'retirementFactor2',
                        'percent'
                      )
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={8}
                  className=""
                  style={{ marginRight: '15px', fontSize: '12px' }}
                >
                  What percent of phase-2 expenses do you expect during phase-3
                  of retirement?
                </Grid>
                <Grid item xs={3}>
                  <input
                    name="Disc Factor 3"
                    className="planner-input"
                    value={formDetails.retirementFactor3}
                    type={'text'}
                    onBlur={(event) => handleBlur(event, 'retirementFactor3')}
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'retirementFactor3',
                        'percent'
                      )
                    }
                  />
                </Grid>
                {!storeValues.useAggNonHousingExpense && (
                  <>
                    <Grid
                      item
                      xs={8}
                      className=""
                      style={{
                        marginRight: '15px',
                        marginTop: '10px',
                        fontSize: '12px',
                      }}
                    >
                      Would you like to apply these adjustments to itemized
                      discretionary expenses?
                    </Grid>

                    <Grid item xs={3}>
                      <Controls.RadioGroup
                        name="Disc Same Factor Across"
                        value={formDetails.discSameFactorAcross || false}
                        rowType={true}
                        onChange={(event) =>
                          handleFormFieldChange(
                            event,
                            'discSameFactorAcross',
                            'radio'
                          )
                        }
                        items={radioOptions}
                      ></Controls.RadioGroup>
                    </Grid>
                  </>
                )}
              </Grid>
            ) : currentStep !== 0 &&
              currentStep !== 1 &&
              currentStep !== 2 &&
              !formDetails.discSameFactorAcross ? (
              <Grid container spacing={2} className="">
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    padding: '5px',
                  }}
                >
                  Adjust Itemized Discretionary Expenses
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    padding: '5px',
                  }}
                >
                  You may adjust down your annual expense for {stepName.label}
                </Grid>
                {!formDetails.discSameFactorAcross && (
                  <>
                    <Grid
                      item
                      xs={8}
                      className=""
                      style={{
                        marginRight: '15px',
                        marginTop: '10px',
                        fontSize: '12px',
                      }}
                    >
                      Would you be willing to reduce this amount if you were to
                      have a budget deficit?
                    </Grid>
                    <Grid item xs={3}>
                      <Controls.RadioGroup
                        name="radioButton"
                        value={formDetails.disc[stepName.value] || false}
                        rowType={true}
                        onChange={(event) =>
                          handleDiscFormFieldChange(
                            event,
                            stepName.value,
                            'radio'
                          )
                        }
                        items={radioOptions}
                      ></Controls.RadioGroup>
                    </Grid>
                  </>
                )}
                {formDetails.disc[stepName.value] && (
                  <>
                    <Grid
                      item
                      xs={8}
                      className=""
                      style={{ marginRight: '15px', fontSize: '12px' }}
                    >
                      What is the most conservative percent of expense you would
                      be willing to consider, if you must reduce during planning
                      horizon?
                    </Grid>
                    <Grid item xs={3}>
                      <input
                        name={stepName.value}
                        className=""
                        value={formDetails.horPercentValue}
                        type={'text'}
                        onBlur={(event) => handleBlur(event, 'horPercentValue')}
                        onChange={(event) =>
                          handleFormFieldChange(
                            event,
                            'horPercentValue',
                            'percent'
                          )
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={8}
                      className=""
                      style={{ marginRight: '15px', fontSize: '12px' }}
                    >
                      What is the most conservative percent of expense you would
                      be willing to consider, if you must reduce during phase-1
                      of retirement?
                    </Grid>
                    <Grid item xs={3}>
                      <input
                        name={stepName.value}
                        className="planner-input"
                        value={formDetails.retPercentValue}
                        type={'text'}
                        onBlur={(event) => handleBlur(event, 'retPercentValue')}
                        onChange={(event) =>
                          handleFormFieldChange(
                            event,
                            'retPercentValue',
                            'percent'
                          )
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            ) : (
              <></>
            )}
            <SolutionsJourneyButtons
              currentStep={currentStep}
              isFormValid={isFormValid}
              handleNext={() => onReduxUpdate('next')}
              isLastStep={isLastStep}
              handlePrevious={() => onReduxUpdate('previous')}
            />
          </div>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
              <Alert severity="info">
                <p>{expenseTypeMsg}</p>
              </Alert>
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  };

  return renderSteps();
}
