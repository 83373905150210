import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import ReactRouter from './navigation/Router';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './styles/retirementPlan.scss';
import './styles/newUIV5.scss';
import './styles/responsive.scss';
import Website from './components/Website';
import 'core-js/features/url-search-params';
import { SnackbarProvider } from 'notistack';

const App = () => {
  const path = window.location.pathname;
  return (
    <Provider store={store}>
      <SnackbarProvider
        iconVariant={{
          info: null,
        }}
      >
        <Router history={history}>
          <Switch>
            <Route exact path={'/'} component={Website} />
            <ReactRouter />
          </Switch>
        </Router>
      </SnackbarProvider>
    </Provider>
  );
};
export default App;
