import Typography from '@mui/material/Typography';
import {
  renderFormInputRow,
  renderFormInputRowName,
  renderFooterSubmitCancelButtons,
  renderRadioGroup,
  renderSelectAccountDropdown,
  renderFormDateField,
  DeleteConfirmation,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook

const BuildLongTermCareInsuranceComponentV3 = (props) => {
  const {
    state,
    concatContent,
    memberOptions,
    setLastStepInParent,
    isFormValid,
    formErrors,
    expandedPanel,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleInsurance',
    mainTab: 'longTermCare',
    subTab: 'ltcIns',
    countVal: 'numLtcIns',
  };

  const { formDetails, isEditable, isAddNew } = state;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();
  // Use the delete confirmation hook

  return (
    <Typography>
      <>
        <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
          {renderSelectAccountDropdown(
            finalExistingItems,
            defaultName,
            openDeleteConfirmation,
            props,
            state,
            moduleObject
          )}
        </div>

        {(isAddNew ||
          (finalExistingItems && finalExistingItems.length > 0)) && (
            <div className="insured_main_outer">
              {isAddNew ? (
                <>
                  {renderRadioGroup(
                    state,
                    props,
                    memberOptions,
                    moduleObject,
                    'insured',
                    'Insured person',
                    true, //isMandatory
                    formErrors
                  )}
                </>
              ) : (
                <>
                  {renderFormInputRow(
                    'text',
                    'tlInsured',
                    'insured',
                    'Insured person',
                    'textRadioButton',
                    moduleObject,
                    state,
                    props,
                    undefined,
                    true, //isDisabled,
                    true, // isMandatory
                    [],
                    isAddNew,
                    isEditable, 
                    isAddNew
                  )}



                  <div className="insured_main_outer"
                    style={{ display: isEditable && !isAddNew ? 'block' : 'none' }}
                  >
                    {renderFormInputRowName(
                      'text',
                      'tlName',
                      'name',
                      'Short name of policy',
                      'text',
                      state,
                      moduleObject,
                      props,
                      finalExistingItems,
                      true, 
                      formErrors, 

                      isEditable
                    )}
                  </div>

                </>
              )}
              {renderFormInputRow(
                'text',
                'tlPremium',
                'premium',
                'Annual Premium',
                'currency',
                moduleObject,
                state,
                props,
                undefined,
                !isEditable, //isDisabled,
                true, // isMandatory
                formErrors,
                isAddNew,
                isEditable
              )}
              {renderFormInputRow(
                'text',
                'tlCashValueAnnual',
                'cashValueAnnual',
                'Cash value to be received per year',
                'currency',
                moduleObject,
                state,
                props,
                undefined,
                !isEditable, //isDisabled,
                true, // isMandatory
                formErrors,
                isAddNew,
                isEditable
              )}
              {renderFormInputRow(
                'text',
                'tlCashValueDistributionYrs',
                'cashValueDistributionYrs',
                'Number of years that the cash value may be paid',
                'number',
                moduleObject,
                state,
                props,
                undefined,
                !isEditable, //isDisabled,
                true, // isMandatory
                formErrors,
                isAddNew,
                isEditable
              )}
              {renderFormDateField(
                'coverageStartYr',
                'Year when coverage started or expected to start',
                'number',
                moduleObject,
                state,
                props,
                'year',
                true,
                false,
                [],
                isAddNew,
                isEditable
              )}
              {isAddNew && (
                <div className="insured_main_outer">
                  {renderFormInputRowName(
                    'text',
                    'tlName',
                    'name',
                    'Short name of policy',
                    'text',
                    state,
                    moduleObject,
                    props,
                    finalExistingItems,
                    true,
                    formErrors,
                    isAddNew, 
                    isEditable
                  )}
                </div>
              )}
              {renderFooterSubmitCancelButtons(
                state,
                moduleObject,
                props,
                finalExistingItems,
                isFormValid
              )}
            </div>
          )}

        {/* Delete Confirmation Dialog */}
        {DeleteConfirmation(
          props,
          isDeleteConfirmationOpen,
          closeDeleteConfirmation,
          handleDeleteConfirmation,
          nameToDelete,
          moduleObject
        )}
      </>
    </Typography>
  );
};

export default BuildLongTermCareInsuranceComponentV3;
