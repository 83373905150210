import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Controls from './controls/Controls';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isEqual } from 'lodash';
import '../styles/advisorhome.scss';
import { selectedClientIdSelector } from '../selectors/clientsInfoSelector';
import {
  selectedPlanIdForClientIdSelector,
  planDetailsByPlanIdSelector,
  allPlannerMenuItemsSelector,
  plannerDetailsLoadingSelector,
  simulatePlanDetailsLoadingSelector,
  selectedChartSelector,
  getNumberOfRuns,
  allTaxSlabsSelector,
  getInvAssetAllocationsByAdvisorIdSelector,
  invAssetAllocationsByAdvisorIdLoadingSelector,
  getInvMarketCorrelationsByAdvisorIdSelector,
  invMarketCorrelationsByAdvisorIdLoadingSelector,
} from '../selectors/plannerDetailsSelector';
import {
  getPlansDetailsAction,
  clearPlanDetailsFromStore,
  simulatePlanDetailsByPlanId,
  saveAndUpdatePlanDetailsByPlanId,
  simulatePlanDetailsByPlanIdSuccessAction,
  getInvAssetAllocationsByAdvisorIdAction,
  getInvMarketCorrelationsByAdvisorIdAction,
} from '../redux/actions/plannerDetailsAction';
import {
  setSelectedClientIdAction,
  getClientsByAdvisorIdAction,
  getAdvisorAssetsUnderManagementAction,
  getAdvisorAssetsUnderManagementCompanyAUMAction,
  getInvMarketExpectationsByAdvisorIdAction,
  getClientsNotUpdatedForNumDaysAction,
  getClientsMeetingsForNumDaysAction,
  setTenureAction,
  setClientTenureAction,
  getForecastInvMktExpListByAdvisorIdAction,
  getAssetAllocListByClientIdAction,
  setAllocationIdAction,
  setForecastIdAction,
} from '../redux/actions/clientsInfoAction';
import {
  getPlansByClientIdAction,
  getMeetingDetailsByMeetingIdAction,
  setSelectedPlanForClientId,
  getPlansByClientIdSuccessAction,
  setClientViewSelected,
  setSelectedDisplayTermAction,
  setSelectedHorizonTermAction,
  setSelectedNumberOfRuns,
  clearPushPinCharts,
  saveAndUpdateMeetingDetailsByMeetingIdAction,
  updateUserMobileAction,
  getUserInformationByEmailIdAction,
} from '../redux/actions/plansAction';
import { resetSimulateBeforeAfterValues } from '../redux/actions/simulateBeforeAfterAction';
import { setInitialReportOptions } from '../redux/actions/reportsAction';
import ConfirmModal from './shared/ConfirmModal';
import { getCurrentDateTime } from '../utils/apirequestutil';
import {
  createNewClient,
  createNewPlan,
  copyDefaultPlan,
  updateInvMarketExpectationsByAdvisorId,
  sendEmail,
  selectSavedPlanByPlanIdV2,
  deleteMeeting,
  updateInvAssetAllocationsByRiskLevelByAdvisorId,
  updateInvMarketCorrelationsByAdvisorId,
} from '../services/dashboardSvc';
import { assetMixListValues } from '../constants/dashboard';
import { DEFAULT_PLAN_ID } from '../services/CONSTANTS';
import DashboardSummaryTabsContainer from './DashboardSummaryTabsContainer';
import {
  getOutputResponseByPlanIdAction,
  clearOutputResponseAction,
} from '../redux/actions/outputResponseDetailsAction';
import { USERTYPES } from '../constants/common';
import { getNewClientMailBody } from '../templates/utils';
import settingDollar from '../images/setting-dollar.svg';
import bell from '../images/bell.svg';
import ChartContainer from './ChartContainer';
import EditIcon from '@mui/icons-material/Edit';
import {
  outputResponseDashboardSummaryDetailsByPlanIdSelector,
  outputResponseLoadingSelector,
  outputResponseFAQsSelector,
  outputResponseGoalsSelector,
  outputResponseChartsByPlanIdSelector,
  outputResponsePlannerDetailsByPlanIdSelector,
  outputResponseChronoDetailsByPlanIdSelector,
  outputResponsePlanHighlightsSelector,
  outputResponseAdvisorHomeSelector,
  isOutputResponseDataAvailable,
} from '../selectors/outputResponseDetailsSelector';
import {
  setVariblePieChartOptions,
  setSpiderChartOptions,
  setRadialCircularChartOptions,
  setTimelineChartOptions,
} from '../components/shared/ChartData';
import TimelineChartContainer from './TimelineChartContainer';
import Stack from '@mui/material/Stack';
import history from '../history';
import { orange, yellow, green, red, grey } from '@mui/material/colors';
import add from '../images/add.svg';
import save from '../images/save.svg';
import uilCalender from '../images/uil_calender.svg';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import HomeChartComponent from './shared/HomeChartComponent';
import { transformTaxStateOptions } from '../redux/transformers/apiToClientResponseTransformer';
import '../styles/controls/dialog.scss';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import edit_pencil from '../images/edit_pencil.svg';
import pdf_page_plus_icon from '../images/pdf_page_plus_icon.svg';
import delete_icon from '../images/delete_icon.svg';
import {
  validatePhoneNumber,
  validateEmailId,
  validateInputFieId,
  formatPhoneNumber,
  validateNamesWithoutSpaces,
} from '../utils/validators';
import { transformBackToAPIType } from '../helpers/ClientHelper';
import Alert from '@mui/material/Alert';

const tenures = [
  { label: '1 year', value: 365 },
  { label: '9 Months', value: 270 },
  { label: '6 Months', value: 180 },
  { label: 'All', value: 0 },
];

const clientTenures = [
  { label: 'All', value: 1000 },
  { label: '1 week', value: 7 },
  { label: '3 week', value: 21 },
  { label: 'Today', value: 0 },
];

const percentageFields = [
  'stockPercent',
  'bondPercent',
  'shortTermPercent',
  'cashPercent',
  'reitPercent',
  'altPercent',
  'commoditiesPercent',
  'cryptoPercent',
  'invReturn',
  'invVolatility',
];

const AntSwitch = styled(Switch)(({ theme, dual }) => ({
  width: '28px  !important',
  height: '16px  !important',
  padding: '0px  !important',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '12px  !important',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    color: '#fff',
    padding: '2px  !important',
    position: 'absolute !important',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#6ccea6' : '#225ea8',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: '12px  !important',
    height: '12px  !important',
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : dual
          ? '#225ea8'
          : 'grey',
    boxSizing: 'border-box',
  },
}));

class NewHome extends Component {
  marketValueField = React.createRef();
  assetMixField = React.createRef();
  correlationValueField = React.createRef();
  state = {
    clientOnboardingModalOpen: false,
    planOnboardingModalOpen: false,
    firstPerson: '',
    secondPerson: '',
    newPlanName: '',
    clientEmailId: '',
    openSnackbar: false,
    isApiFailed: false,
    openPlanSnackbar: false,
    isLoading: false,
    selectedClientType: '',
    selectedClientState: '',
    advisorStatsClientType: [],
    marketProfileRequest: {},
    isFormValid: true,
    derivedInvMarketExpectations: [],
    isInvMarketSnackbarOpen: false,
    isInvMarketCorrelationsSnackbarOpen: false,
    newClientNotification: false,
    activeCard: 'clients',
    timeLineChecked: true,
    isRecommendedRiskLevelChecked: true,
    isMarketForecastChecked: true,
    allClientAgeStats: true,
    localMeetingDetail: {},
    localClientDetail: {},
    backUpLocalClientDetail: {},
    loading: false,
    isEditable: false,
    isAddMeeting: false,
    isDirectUser: false,
    isClientUser: false,
    isUserTypeSet: false,
    newClientAddition: false,
    newClientMailId: '',
    isSimulateCalled: false,
    recommendedRiskLevels: [],
    selectedValue: '',
    derivedInvMarketCorrelations: [],
    selectedCorrelation: '',
    correlationInfo: {},
  };

  handleCorrelationChange = (event) => {
    let { value: selectedCorrelation } = event.target;
    const { derivedInvMarketCorrelations } = this.state;
    const correlationInfo = derivedInvMarketCorrelations.find(
      (c) => c.value === selectedCorrelation
    );
    this.setState({ selectedCorrelation, correlationInfo });
  };

  arraymove = (arr) => {
    const fromIndex = arr.map((a) => a[0]).indexOf('inflation');
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(0, 0, element);
    return arr;
  };

  derivedInvMarketExpectationsByAdvisorId = () => {
    const { invMarketExpectationsByAdvisorId } = this.props;
    var excludedItems = ['advisorId', 'productId', 'companyName'];
    let derivedInvMarketExpectations =
      (
        invMarketExpectationsByAdvisorId &&
        Object.entries(invMarketExpectationsByAdvisorId)
      ).filter((f) => excludedItems.indexOf(f[0]) === -1) || [];
    derivedInvMarketExpectations = derivedInvMarketExpectations.map((value) => {
      let newInvReturnPercentValue = value[1] && value[1].invReturn;
      let newInvVolatilityPercentValue = value[1] && value[1].invVolatility;
      let newInvDividendPercentValue = value[1] && value[1].invDividend;
      if (newInvReturnPercentValue.toString().includes('%')) {
        newInvReturnPercentValue = parseFloat(newInvReturnPercentValue) / 100;
      }
      newInvReturnPercentValue = parseFloat(
        parseFloat(newInvReturnPercentValue) * 100
      ).toFixed(1);
      if (newInvVolatilityPercentValue.toString().includes('%')) {
        newInvVolatilityPercentValue =
          parseFloat(newInvVolatilityPercentValue) / 100;
      }
      newInvVolatilityPercentValue = parseFloat(
        parseFloat(newInvVolatilityPercentValue) * 100
      ).toFixed(1);
      if (newInvDividendPercentValue.toString().includes('%')) {
        newInvDividendPercentValue =
          parseFloat(newInvDividendPercentValue) / 100;
      }
      newInvDividendPercentValue = parseFloat(
        parseFloat(newInvDividendPercentValue) * 100
      ).toFixed(1);
      const newInvReturnValue = `${newInvReturnPercentValue}`;
      const newInvVolatilityValue = `${newInvVolatilityPercentValue}`;
      const newInvDividendValue = `${newInvDividendPercentValue}`;
      return {
        ...value,
        1: {
          ...value[1],
          invReturn: newInvReturnValue,
          invVolatility: newInvVolatilityValue,
          invDividend: newInvDividendValue,
        },
      };
    });
    return derivedInvMarketExpectations.length > 0
      ? this.arraymove(derivedInvMarketExpectations)
      : [];
  };

  deriveInvMarketCorrelationsByAdvisorId = () => {
    const { invMarketCorrelations } = this.props;
    var excludedItems = ['advisorId', 'productId', 'companyName'];
    let derivedInvMarketCorrelations =
      (invMarketCorrelations && Object.entries(invMarketCorrelations)).filter(
        (f) => excludedItems.indexOf(f[0]) === -1
      ) || [];
    const dropdownData = derivedInvMarketCorrelations.map(([key, value]) => {
      return {
        label: value.assetClass, // Label for dropdown (e.g., "Stock", "Bond")
        value: key, // Value for the dropdown option (e.g., "stock", "bond")
        details: Object.entries(value)
          .map(([subKey, subValue]) => {
            return {
              subAssetClass: subValue.assetClass,
              corrCoeff: subValue.corrCoeff,
              subKey: subKey,
            };
          })
          .filter((item) => item.subAssetClass), // Filter out any undefined or irrelevant entries
      };
    });
    return dropdownData;
  };

  componentDidMount() {
    const {
      userDetails,
      userDetails: { emailId },
      triggersetSelectedPlanIdForClientId,
      triggersetCurrentPlansforClientId,
      triggerSetSelectedClientIdAction,
      advisorAssetsUnderManagement,
      companyAUM,
      triggerGetAdvisorAssetsUnderManagement,
      triggerGetAdvisorAssetsUnderManagementCompanyAUM,
      clientsNotUpdated,
      clientMeetings,
      invMarketExpectationsByAdvisorId,
      triggerGetInvMarketExpectationsByAdvisorId,
      triggerGetClientsNotUpdatedForNumDays,
      triggerGetClientsMeetingsForNumDays,
      triggerGetPlansByClientId,
      tenure,
      clientTenure,
      plans,
      selectedClientId,
      selectedPlanId,
      triggerGetInvAssetAllocationsByAdvisorId,
      triggerGetInvMarketCorrelationsByAdvisorId,
    } = this.props;
    const { isUserTypeSet } = this.state;
    const isDirect = userDetails?.userType?.toLowerCase() === 'direct' || false;
    const isClient = userDetails?.userType?.toLowerCase() === 'client' || false;
    if (isUserTypeSet === false) {
      let activeCard = this.state.activeCard;
      if (isDirect || isClient) {
        activeCard = 'clients';
      }
      this.setState({
        activeCard: activeCard,
        isDirectUser: isDirect,
        isClientUser: isClient,
        isUserTypeSet: true,
      });
    }
    if (
      invMarketExpectationsByAdvisorId &&
      Object.keys(invMarketExpectationsByAdvisorId).length === 0 &&
      !isDirect &&
      !isClient
    ) {
      triggerGetInvMarketExpectationsByAdvisorId(emailId);
    } else {
      const derivedInvMarketExpectations =
        this.derivedInvMarketExpectationsByAdvisorId();
      this.setState({ derivedInvMarketExpectations });
    }
    if (
      advisorAssetsUnderManagement &&
      Object.keys(advisorAssetsUnderManagement).length === 0 &&
      !isDirect &&
      !isClient
    ) {
      triggerGetAdvisorAssetsUnderManagement(emailId);
    }
    if (
      companyAUM &&
      Object.keys(companyAUM).length === 0 &&
      !isDirect &&
      !isClient
    ) {
      triggerGetAdvisorAssetsUnderManagementCompanyAUM(emailId);
    }
    if (
      clientsNotUpdated &&
      clientsNotUpdated.length === 0 &&
      !isDirect &&
      !isClient
    ) {
      const req = {
        advisorId: emailId,
        numOfDays: tenure,
      };
      triggerGetClientsNotUpdatedForNumDays(req);
    }
    //if (invAssetAllocations && invAssetAllocations.length === 0) {
    triggerGetInvAssetAllocationsByAdvisorId(emailId);
    triggerGetInvMarketCorrelationsByAdvisorId(emailId);
    //}
    if (
      clientMeetings &&
      clientMeetings.length === 0 &&
      !isDirect &&
      !isClient
    ) {
      const req = {
        advisorId: emailId,
        numOfDays: clientTenure,
      };
      triggerGetClientsMeetingsForNumDays(req);
    }
    triggersetCurrentPlansforClientId(plans);
    if (!isDirect && !isClient) {
      triggerSetSelectedClientIdAction(selectedClientId);
      triggersetSelectedPlanIdForClientId(selectedPlanId);
    } else {
      triggerSetSelectedClientIdAction(emailId);
      if (!plans || plans.length <= 0) {
        const payload = { clientId: emailId };
        triggerGetPlansByClientId(payload);
      }
    }
    if (selectedClientId) {
      this.setState({ activeCard: 'clients' });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedPlanId,
      selectedClientId,
      plans,
      clients,
      planDetailsByPlanId,
      triggersetSelectedHorizonTermForClientId,
      triggersetSelectedDisplayTermForClientId,
      triggersetSelectedNumberofRuns,
      allMenuItems,
      invMarketExpectationsByAdvisorId,
      triggersetCurrentPlansforClientId,
      triggersetSelectedPlanIdForClientId,
      triggerSetSelectedClientIdAction,
      triggerGetClientsByAdvisorId,
      triggerGetPlansByClientId,
      triggerGetMeetingsByMeetingId,
      triggerGetClientsNotUpdatedForNumDays,
      triggerGetClientsMeetingsForNumDays,
      triggerGetUserDetailsByEmailId,
      userDetails: { emailId, userType },
      clientsNotUpdated,
      triggerSetTenure,
      triggerSetClientTenure,
      tenure,
      clientTenure,
      triggerClearOutputResponseAction,
      meetingDetail,
      plannerDetailsLoading,
      simulatePlanDetailsLoading,
      clientDetails,
      advisorHome,
      clientMeetings,
      invAssetAllocations,
      invMarketCorrelations,
    } = this.props;
    const {
      timeLineChecked,
      selectedMeetingId,
      localMeetingDetail,
      localClientDetail,
      isLoading,
      loading,
      isDirectUser,
      isClientUser,
      isAddMeeting,
      newClientAddition,
      newClientMailId,
      isSimulateCalled,
      recommendedRiskLevels,
      derivedInvMarketCorrelations
    } = this.state;
    const { selectedMeetingId: prevSelectedMeetingId } = prevState;
    const {
      selectedPlanId: prevSelectedPlanId,
      selectedClientId: prevSelectedClientId,
      planDetailsByPlanId: prevPlanDetailsByPlanId,
      invMarketExpectationsByAdvisorId: prevInvMarketExpectationsByAdvisorId,
      clients: prevClients,
      plans: prevPlans,
      clientsNotUpdated: prevClientsNotUpdated,
      tenure: prevTenure,
      clientTenure: prevClientTenure,
      meetingDetail: prevMeetingDetail,
      clientDetails: prevClientDetails,
      plannerDetailsLoading: prevPlannerDetailsLoading,
      simulatePlanDetailsLoading: prevSimulatePlanDetailsLoading,
      advisorHome: prevAdvisorHome,
      clientMeetings: prevClientMeetings,
      invAssetAllocations: prevInvAssetAllocations,
      invMarketCorrelations: prevInvMarketCorrelations,
    } = prevProps;
    const { clientTypeMenuItems = [] } = allMenuItems || {};
    if (
      newClientAddition &&
      advisorHome &&
      Object.keys(advisorHome).length > 0 &&
      !isEqual(advisorHome, prevAdvisorHome)
    ) {
      if (userType === USERTYPES.ADVISOR) {
        history.push({
          pathname: '/build',
        });
      } else {
        this.setState({ newClientAddition: false, isLoading: false });
      }
    }
    if (
      invAssetAllocations.length > 0 &&
      recommendedRiskLevels.length === 0
      // &&
      // !isEqual(invAssetAllocations, prevInvAssetAllocations)
    ) {
      const tempRecommendedRiskLevels =
        invAssetAllocations.map((m) => {
          return {
            riskAppetite: m.riskAppetite,
            productId: m.productId,
            riskLevel: m.riskLevel,
          };
        }) || [];
      const selectedRecommendedRiskAppetite =
        tempRecommendedRiskLevels.length > 0
          ? tempRecommendedRiskLevels[0].productId
          : '';
      this.deriveRecommendedRiskLevelValues(selectedRecommendedRiskAppetite);
      this.setState({
        recommendedRiskLevels: tempRecommendedRiskLevels,
        selectedRecommendedRiskAppetite,
      });
    }
    if (selectedClientId && selectedClientId !== prevSelectedClientId) {
      const payload = { clientId: selectedClientId };
      triggerGetPlansByClientId(payload);
    }
    if (selectedMeetingId && selectedMeetingId !== prevSelectedMeetingId) {
      triggerGetMeetingsByMeetingId(selectedMeetingId);
    }
    if (
      clientMeetings &&
      !isEqual(clientMeetings, prevClientMeetings) &&
      clientMeetings.length > 0
    ) {
      this.setState({ selectedMeetingId: clientMeetings[0].meetingId });
    }
    if (plans && plans.length > 0 && selectedPlanId === '') {
      triggersetSelectedPlanIdForClientId(plans[0].value);
    }
    if (
      selectedPlanId &&
      selectedPlanId !== '' &&
      prevSelectedPlanId !== selectedPlanId
    ) {
      this.getSelectedPlanIdDetails();
    }
    if (
      selectedPlanId !== '' &&
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0 &&
      !isEqual(prevPlanDetailsByPlanId, planDetailsByPlanId) &&
      planDetailsByPlanId.planid === selectedPlanId &&
      !isSimulateCalled // this flag is to make sure simulate is not called twice as we come to this page from diff runcodes and we change runcode to 0 in this page which is different to what was saved in redux
    ) {
      const {
        moduleSettings: { model = {} } = {},
        moduleInvestments: { markets = {} } = {},
      } = planDetailsByPlanId;
      const { displayTerm, horizon } = model;
      triggersetSelectedHorizonTermForClientId(horizon);
      triggersetSelectedDisplayTermForClientId(displayTerm);
      this.handleSimulate(timeLineChecked);
    }
    if (tenure !== prevTenure && !isDirectUser && !isClientUser) {
      const req = {
        advisorId: emailId,
        numOfDays: tenure,
      };
      triggerGetClientsNotUpdatedForNumDays(req);
      triggerSetTenure(tenure);
      triggerClearOutputResponseAction();
    }
    if (clientTenure !== prevClientTenure) {
      const req = {
        advisorId: emailId,
        numOfDays: clientTenure,
      };
      triggerGetClientsMeetingsForNumDays(req);
      triggerSetClientTenure(clientTenure);
    }
    if (
      !isAddMeeting &&
      ((Object.keys(localMeetingDetail).length <= 0 &&
        Object.keys(meetingDetail).length > 0) ||
        !isEqual(meetingDetail, prevMeetingDetail))
    ) {
      this.setState({
        localMeetingDetail: meetingDetail,
        backUpLocalMeetingDetail: meetingDetail,
      });
      if (Object.keys(meetingDetail).length > 0) {
        triggerGetUserDetailsByEmailId(meetingDetail.clientId);
      }
    }
    if (
      (Object.keys(localClientDetail).length <= 0 &&
        Object.keys(clientDetails).length > 0) ||
      !isEqual(clientDetails, prevClientDetails)
    ) {
      this.setState({
        localClientDetail: clientDetails,
        backUpLocalClientDetail: clientDetails,
      });
    }
    if (
      clientsNotUpdated &&
      clientsNotUpdated.length > 0 &&
      !isEqual(clientsNotUpdated, prevClientsNotUpdated)
    ) {
      if (newClientAddition && newClientMailId !== '') {
        triggersetSelectedPlanIdForClientId('');
        triggerSetSelectedClientIdAction(newClientMailId);
        if (!plans || plans.length <= 0) {
          const payload = { clientId: newClientMailId };
          triggerGetPlansByClientId(payload);
        }
        this.setState({
          newClientMailId: '',
        });
      } else if (newClientAddition === false) {
        triggerSetSelectedClientIdAction('');
        triggersetSelectedPlanIdForClientId('');
        triggersetCurrentPlansforClientId([]);
      }
    }
    if (
      !isEqual(
        prevInvMarketExpectationsByAdvisorId,
        invMarketExpectationsByAdvisorId
      )
    ) {
      const derivedInvMarketExpectations =
        this.derivedInvMarketExpectationsByAdvisorId();
      this.setState({ derivedInvMarketExpectations });
    }
    if (!isEqual(invMarketCorrelations, prevInvMarketCorrelations) || derivedInvMarketCorrelations.length === 0) {
      const tempDerivedInvMarketCorrelations =
        this.deriveInvMarketCorrelationsByAdvisorId();
      if (
        tempDerivedInvMarketCorrelations &&
        tempDerivedInvMarketCorrelations.length > 0
      ) {
        const selectedCorrelation = tempDerivedInvMarketCorrelations[0]?.value;
        const correlationInfo = tempDerivedInvMarketCorrelations.find(
          (m) => m.value === selectedCorrelation
        );
        this.setState({
          derivedInvMarketCorrelations: tempDerivedInvMarketCorrelations,
          selectedCorrelation,
          correlationInfo,
        });
      }
    }
    if (
      plans &&
      plans.length > 0 &&
      selectedClientId !== '' &&
      !isEqual(plans, prevPlans)
    ) {
      triggersetCurrentPlansforClientId(plans);
    }
    if (
      (plannerDetailsLoading || simulatePlanDetailsLoading || isLoading) &&
      !loading
    ) {
      this.setState({ loading: true });
    } else if (
      !plannerDetailsLoading &&
      !simulatePlanDetailsLoading &&
      !isLoading &&
      loading
    ) {
      this.setState({ loading: false });
    }
  }

  handleMeetingInfoCancel = () => {
    const { backUpLocalClientDetail, backUpLocalMeetingDetail } = this.state;
    this.setState({
      isEditable: false,
      isAddMeeting: false,
      localClientDetail: { ...backUpLocalClientDetail },
      localMeetingDetail: { ...backUpLocalMeetingDetail },
      ismobileNumberValid: undefined,
      ismeetingNameValid: undefined,
    });
  };

  onClientMeetingChange = (event) => {
    let { value: meetingId } = event.target;
    this.setState({
      selectedMeetingId: meetingId,
      isEditable: false,
      isAddMeeting: false,
    });
    //TODO : Get User's Mobile based on selected client id from get meetings
  };

  onClientChange = (event) => {
    let { value: clientId } = event.target;
    const { triggerSetSelectedClientIdAction } = this.props;
    this.setState({
      timeLineChecked: true,
      newClientAddition: false,
      isSimulateCalled: false,
    });
    triggerSetSelectedClientIdAction(clientId);
  };

  onPlanChange = (event, id) => {
    let planId =
      event && event.target && event.target.value !== ''
        ? event.target.value
        : id;
    const {
      triggersetSelectedPlanIdForClientId,
      triggerClearPlanDetailsFromStore,
    } = this.props;
    this.setState({
      timeLineChecked: true,
    });
    triggerClearPlanDetailsFromStore();
    triggersetSelectedPlanIdForClientId(planId);
  };

  gotoDashboard = () => {
    const {
      selectedPlanId,
      selectedClientId,
      plans,
      triggerSetClientViewSelected,
    } = this.props;
    const planInfo = {
      selectedPlan: selectedPlanId,
      plans: plans,
      selectedClient: selectedClientId,
    };
    triggerSetClientViewSelected(true);
  };

  handleClientOnboardingModalOpen = (val) => {
    const { allMenuItems: { clientTypeMenuItems = [] } = {} } = this.props;
    this.setState({
      clientOnboardingModalOpen: val,
      firstPerson: '',
      secondPerson: '',
      newPlanName: '',
      clientEmailId: '',
      clientMobileNumber: '',
      selectedClientType: clientTypeMenuItems[0]?.value || '',
      selectedClientState: '',
      isfirstPersonValid: true,
      issecondPersonValid: true,
      isclientEmailIdValid: true,
      isclientMobileNumberValid: true,
      isnewPlanNameValid: true,
    });
  };

  onClientTypeChange = (event) => {
    const { value: selectedClientType } = event.target;
    this.setState({ selectedClientType });
  };

  onClientStateChange = (event) => {
    const { value: selectedClientState } = event.target;
    this.setState({ selectedClientState });
  };

  handleChange = (e, field) => {
    const { value = '' } = e.target;
    let newValue = value;
    let isValid = true;
    switch (field && field.toLowerCase()) {
      case 'firstperson':
      case 'secondperson':
        isValid = validateNamesWithoutSpaces(value);
        break;
      case 'newplanname':
        isValid = validateInputFieId(value);
        break;
      case 'clientmobilenumber':
        newValue = formatPhoneNumber(value);
        isValid = validatePhoneNumber(newValue); // returns true if valid
        break;
      case 'clientemailid':
        isValid = validateEmailId(value);
        break;
      default:
        break;
    }
    this.setState({
      [e.target.name]: newValue,
      [`is${field}Valid`]: isValid,
    });
  };

  setClientAlert = (e) => {
    this.setState({
      newClientNotification: e.target.checked,
    });
  };

  renderNewClientOnboardingDetails = () => {
    const {
      newPlanName,
      firstPerson,
      secondPerson,
      selectedClientType,
      selectedClientState,
      lastName,
      clientEmailId,
      clientMobileNumber,
      newClientNotification,
      isfirstPersonValid,
      issecondPersonValid,
      isclientEmailIdValid,
      isclientMobileNumberValid,
      isnewPlanNameValid,
    } = this.state;
    const {
      allMenuItems: { clientTypeMenuItems = [] } = {},
      clientsNotUpdated = [],
      alltaxSlabs,
    } = this.props || {};
    const taxStateVals = transformTaxStateOptions(alltaxSlabs);
    const isClientExist = clientsNotUpdated.some(
      (client) => client.clientId === clientEmailId
    );
    return (
      <>
        <div className="signup-title-container">
          <span className="signup-title">Add New Client</span>
          <CloseIcon
            className="signup-title-close-icon"
            onClick={() => this.handleClientOnboardingModalOpen(false)}
          />
        </div>
        <div className="signup-body-container">
          <Grid container className="newplan-form-grid" spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <Controls.Input
                name="firstPerson"
                label="First Person"
                value={firstPerson}
                type="text"
                onChange={(event) => this.handleChange(event, 'firstPerson')}
                errorText={
                  firstPerson
                    ? 'Invalid First Person Name'
                    : 'This is a mandatory field'
                }
                isValid={isfirstPersonValid}
                required
                placeholder={'First Name'}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Controls.Input
                name="secondPerson"
                label="Second Person (optional)"
                value={secondPerson}
                type="text"
                onChange={(event) => this.handleChange(event, 'secondPerson')}
                isValid={issecondPersonValid}
                errorText={secondPerson ? 'Invalid Second Person Name' : ''}
                placeholder={'First Name'}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Controls.Input
                name="clientEmailId"
                label="Email Id"
                value={clientEmailId}
                type="text"
                onChange={(event) => this.handleChange(event, 'clientEmailId')}
                errorText={
                  isClientExist
                    ? 'Client with same Email ID already exists'
                    : clientEmailId
                      ? 'Invalid Email ID'
                      : 'This is a mandatory field'
                }
                isValid={!isClientExist && isclientEmailIdValid}
                placeholder={'Email'}
                required
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Controls.Input
                name="clientMobileNumber"
                label="Mobile Number"
                value={clientMobileNumber}
                type="text"
                onChange={(event) =>
                  this.handleChange(event, 'clientMobileNumber')
                }
                errorText={
                  clientMobileNumber
                    ? 'Invalid Mobile Number'
                    : 'This is a mandatory field'
                }
                isValid={isclientMobileNumberValid}
                required
                placeholder="(123) 456-7890"
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Controls.Input
                name="newPlanName"
                label="Plan Name"
                value={newPlanName}
                type="text"
                onChange={(event) => this.handleChange(event, 'newPlanName')}
                errorText={
                  newPlanName
                    ? 'Invalid Plan Name'
                    : 'This is a mandatory field'
                }
                isValid={isnewPlanNameValid}
                placeholder={'Plan Name'}
                required
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} className="plan-dropdown">
              <Controls.Select
                name="clientType"
                label="Client Type"
                value={selectedClientType}
                options={
                  clientTypeMenuItems && clientTypeMenuItems.length > 0
                    ? clientTypeMenuItems
                    : []
                }
                onChange={this.onClientTypeChange}
                required
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} className="plan-dropdown">
              <Controls.Select
                name="clientState"
                label="Client State"
                value={selectedClientState}
                options={
                  taxStateVals && taxStateVals.length > 0 ? taxStateVals : []
                }
                onChange={this.onClientStateChange}
                required
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} className="plan-dropdown"></Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormControlLabel
                control={
                  <AntSwitch
                    checked={newClientNotification}
                    onChange={this.setClientAlert}
                    dual={false}
                  />
                }
                label={`Send client's login information to client through email ?`}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  handlePlanInfoSave = (planInfo) => {
    const { triggerSaveAndUpdatePlanDetailsbyPlanId } = this.props;
    const version = 'v2';
    triggerSaveAndUpdatePlanDetailsbyPlanId({ version, planInfo });
  };

  handleAddMeeting = () => {
    const {
      userDetails: { emailId },
      selectedClientId,
      triggerGetUserDetailsByEmailId,
    } = this.props;
    const { selectedMeetingId, localMeetingDetail } = this.state;
    let selectedClient =
      selectedMeetingId && Object.keys(localMeetingDetail).length > 0
        ? localMeetingDetail?.clientId
        : selectedClientId;
    let localMeetingInfo = {
      meetingId: '',
      meetingName: '',
      clientId: selectedClient,
      advisorId: emailId,
      meetingDate: '',
      firstReminderDays: '-7',
      secondReminderDays: '-1',
      meetingStatus: 'scheduled',
      commsEmail: false,
      commsSMS: false,
      commsPhone: false,
      meetingNotes: [],
    };
    triggerGetUserDetailsByEmailId(selectedClient);
    this.setState({
      isEditable: true,
      selectedMeetingId: '',
      isAddMeeting: true,
      localMeetingDetail: localMeetingInfo,
    });
  };

  handleMeetingInfoSave = () => {
    const {
      triggerSaveAndUpdateMeetingDetailsbyMeetingId,
      triggerGetClientsMeetingsForNumDays,
      triggerGetMeetingsByMeetingId,
      triggerUpdateMobileNumberByEmailId,
      userDetails: { emailId },
      clientTenure,
      clientMeetings,
    } = this.props;
    const { localMeetingDetail, localClientDetail } = this.state;
    triggerSaveAndUpdateMeetingDetailsbyMeetingId(localMeetingDetail);
    const req = {
      advisorId: emailId,
      numOfDays: clientTenure,
    };
    const updateMobileNumberReq = {
      emailId: localMeetingDetail.clientId,
      mobileNumber: localClientDetail.mobileNumber,
    };
    triggerUpdateMobileNumberByEmailId(updateMobileNumberReq);
    // Set the timeout duration in milliseconds (e.g., 5000 milliseconds or 5 seconds)
    const timeoutDuration = 1000;
    //let selMeetingId = '';
    setTimeout(() => {
      // if (clientMeetings && clientMeetings.length > 1) {
      //   selMeetingId = clientMeetings[0].meetingId;
      // }
      triggerGetClientsMeetingsForNumDays(req);
      //triggerGetMeetingsByMeetingId(selMeetingId);
      this.setState({
        isEditable: false,
        isAddMeeting: false,
        backUpLocalClientDetail: localMeetingDetail,
        // selectedMeetingId: selMeetingId,
      });
    }, timeoutDuration);
  };

  handleDeleteMeeting = () => {
    this.deleteMeetingInfo(this.state.selectedMeetingId);
  };

  deleteMeetingInfo = async (selectedMeetingId) => {
    const {
      triggerGetClientsMeetingsForNumDays,
      userDetails: { emailId },
      clientTenure,
      clientMeetings,
    } = this.props;
    //setIsLoading(true);
    try {
      const result = await deleteMeeting(true, selectedMeetingId);
      if (result.status && result.status === 200) {
        const req = {
          advisorId: emailId,
          numOfDays: clientTenure,
        };
        // Set the timeout duration in milliseconds (e.g., 5000 milliseconds or 5 seconds)
        const timeoutDuration = 1000;
        // Call the function after the specified timeout
        setTimeout(() => {
          // let selMeetingId = '';
          // if (clientMeetings && clientMeetings.length > 1) {
          //   selMeetingId =
          //     clientMeetings[0].meetingId !== selectedMeetingId
          //       ? clientMeetings[0].meetingId
          //       : clientMeetings[1].meetingId;
          // }
          triggerGetClientsMeetingsForNumDays(req);
          // this.setState({ selectedMeetingId: selMeetingId });
        }, timeoutDuration);
        this.setState({ isEditable: false });
        //setIsLoading(false);
      }
    } catch (e) { }
  };

  updateClientNamesInDefaultPlanInfo = (planInfo) => {
    const { firstPerson, secondPerson } = this.state;
    const { moduleFamily: { infoCore = {} } = {}, moduleFamily = {} } =
      planInfo || {};
    const { basicsFirst = {}, basicsSecond = {} } = infoCore;
    let tempBasicsFirst = { ...basicsFirst, name: firstPerson };
    let tempBasicsSecond = { ...basicsSecond, name: secondPerson };
    let tempPlanInfo = { ...planInfo };
    tempPlanInfo = {
      ...tempPlanInfo,
      moduleFamily: {
        ...moduleFamily,
        infoCore: {
          ...infoCore,
          basicsFirst: { ...tempBasicsFirst },
          basicsSecond: { ...tempBasicsSecond },
        },
      },
    };
    this.handlePlanInfoSave(tempPlanInfo);
    this.handleClientOnboardingModalOpen(false);
  };

  updateCopiedPlanInfoWithClientName = async (planId) => {
    try {
      const [response] = await Promise.all([selectSavedPlanByPlanIdV2(planId)]);
      if (response && response.status === 200) {
        this.updateClientNamesInDefaultPlanInfo(response.data);
      } else {
        this.setState({
          isApiFailed: true,
          isLoading: false,
        });
      }
    } catch (e) {
      this.setState({
        isApiFailed: true,
        isLoading: false,
      });
    }
  };

  copyDefaultPlan = async (planId, clientId, type) => {
    const {
      userDetails: loggedInUserDetails = {},
      triggerGetPlansByClientId,
      tenure,
      selectedPlanId,
      triggerGetClientsNotUpdatedForNumDays,
    } = this.props;
    const { emailId } = loggedInUserDetails;
    const reqObj = {
      clientId: clientId !== '' ? clientId.toLowerCase() : '',
      advisorId: emailId !== '' ? emailId.toLowerCase() : '',
      sourcePlanId:
        !type && selectedPlanId !== '' ? selectedPlanId : DEFAULT_PLAN_ID,
      targetPlanId: planId,
    };
    try {
      const [response] = await Promise.all([copyDefaultPlan(reqObj)]);
      if (response && response.status === 200) {
        this.setState(
          {
            isApiFailed: false,
            isLoading: false,
          },
          () => {
            if (type === 'client') {
              const req = {
                advisorId: emailId,
                numOfDays: tenure,
              };
              triggerGetClientsNotUpdatedForNumDays(req);
              this.updateCopiedPlanInfoWithClientName(planId);
            } else {
              const payload = {
                clientId: clientId !== '' ? clientId.toLowerCase() : '',
              };
              triggerGetPlansByClientId(payload);
            }
          }
        );
      } else {
        this.setState({
          isApiFailed: true,
          isLoading: false,
        });
      }
    } catch (e) {
      this.setState({
        isApiFailed: true,
        isLoading: false,
      });
    }
  };

  handleAddNewClient = async () => {
    const {
      firstPerson,
      secondPerson,
      newPlanName,
      mobileNumber,
      clientEmailId,
      selectedClientType,
      selectedClientState,
      clientMobileNumber,
      newClientNotification,
      newClientAddition,
      newClientMailId,
    } = this.state;
    const {
      userDetails: loggedInUserDetails = {},
      triggerGetClientsNotUpdatedForNumDays,
      tenure,
      triggersetCurrentPlansforClientId,
    } = this.props;
    const { emailId, name } = loggedInUserDetails;
    this.setState({ isLoading: true, clientOnboardingModalOpen: false });
    const clientName = secondPerson
      ? `${firstPerson} & ${secondPerson}`
      : firstPerson;
    let reqObj = {
      firstPersonFirstName: firstPerson,
      secondPersonFirstName: secondPerson,
      clientId: clientEmailId.toLowerCase(),
      planName: newPlanName,
      advisorId: emailId,
      clientType: selectedClientType,
      stateCode: selectedClientState,
      mobileNumber: clientMobileNumber,
    };
    try {
      triggersetCurrentPlansforClientId([]);
      const [firstResponse] = await Promise.all([createNewClient(reqObj)]);
      if (firstResponse && firstResponse.status === 200) {
        this.setState(
          {
            openSnackbar: true,
            clientOnboardingModalOpen: false,
            // isLoading: false,
            newClientAddition: true,
            newClientMailId: clientEmailId.toLowerCase(),
          },
          () => {
            const req = {
              advisorId: emailId,
              numOfDays: tenure,
            };
            triggerGetClientsNotUpdatedForNumDays(req);
          }
        );
        if (newClientNotification) {
          const mailData = {
            emailId: clientEmailId.toLowerCase(),
            clientName: clientName,
            advisorName: name,
            siteDomain: window.location.origin,
          };
          const reqPayload = {
            createdDate: getCurrentDateTime(),
            message: getNewClientMailBody(mailData),
            notificationId: '',
            subject: 'New account Green`r',
            toAddress: clientEmailId.toLowerCase(),
          };
          const { status } = await sendEmail(reqPayload);
        }
      } else {
        this.setState({
          openSnackbar: true,
          isApiFailed: true,
          isLoading: false,
          clientOnboardingModalOpen: true,
        });
      }
    } catch (e) {
      this.setState({
        openSnackbar: true,
        isApiFailed: true,
        isLoading: false,
        clientOnboardingModalOpen: true,
      });
    }
  };

  getClients = () => {
    const { userDetails: loggedInUserDetails = {} } = this.props;
    const { emailId } = loggedInUserDetails;
    if (emailId && emailId !== '') {
      this.loadClientsByAdvisorId(emailId);
    }
  };

  loadClientsByAdvisorId = (emailId) => {
    const { triggerGetClientsByAdvisorId } = this.props;
    const payload = {
      advisorId: emailId.toLowerCase(),
    };
    triggerGetClientsByAdvisorId(payload);
  };

  handleClose = () => {
    this.setState({
      openSnackbar: false,
      openPlanSnackbar: false,
      isInvMarketSnackbarOpen: false,
      isInvMarketCorrelationsSnackbarOpen: false,
      isAssetMixSnackbarOpen: false,
    });
  };

  handleNewPlan = async () => {
    const {
      userDetails: loggedInUserDetails = {},
      selectedClientId,
      clientsNotUpdated,
      selectedPlanId,
      triggerGetPlansByClientId,
    } = this.props;
    const { emailId, name } = loggedInUserDetails;
    const { newPlanName, mobileNumber } = this.state;
    let selectedClientName = '';
    if (clientsNotUpdated.length > 0) {
      const clientObj = clientsNotUpdated
        .filter((f) => f.clientId !== '' && f.clientName !== '')
        .find((client) => client.clientId === selectedClientId);
      selectedClientName =
        clientObj && Object.keys(clientObj).length > 0
          ? clientObj.clientName
          : '';
    }
    this.setState({ isLoading: true });
    const reqObj = {
      clientId: selectedClientId,
      toPlanName: newPlanName,
      fromPlanId: selectedPlanId,
    };
    try {
      const [firstResponse] = await Promise.all([createNewPlan(reqObj)]);
      if (firstResponse && firstResponse.status === 200) {
        this.setState(
          {
            openPlanSnackbar: true,
            planOnboardingModalOpen: false,
            isLoading: false,
          },
          () => {
            // this.copyDefaultPlan(firstResponse.data, selectedClientId);
            const payload = {
              clientId:
                selectedClientId !== '' ? selectedClientId.toLowerCase() : '',
            };
            triggerGetPlansByClientId(payload);
          }
        );
      } else {
        this.setState({
          isApiFailed: true,
          isLoading: false,
          openPlanSnackbar: true,
          planOnboardingModalOpen: false,
        });
      }
    } catch (e) {
      this.setState({
        openPlanSnackbar: true,
        isApiFailed: true,
        isLoading: false,
        planOnboardingModalOpen: false,
      });
    }
  };

  handleAddPlanModalOpen = (val) => {
    this.setState({
      planOnboardingModalOpen: val,
      newPlanName: '',
      isnewPlanNameValid: undefined,
    });
  };

  renderNewPlanDetails = () => {
    const { newPlanName, isnewPlanNameValid } = this.state;
    return (
      <>
        <div className="signup-title-container">
          <span className="signup-title">Add New Plan</span>
          <CloseIcon
            className="signup-title-close-icon"
            onClick={() => this.handleAddPlanModalOpen(false)}
          />
        </div>
        <div className="signup-body-container">
          <Grid container className="newplan-form-grid" spacing={2}>
            <Grid item>
              <Controls.Input
                label="Plan Name"
                name="newPlanName"
                value={newPlanName}
                type="text"
                onChange={(event) => {
                  this.handleChange(event, 'newPlanName');
                }}
                errorText={
                  newPlanName
                    ? 'Invalid Plan Name'
                    : 'This is a mandatory field'
                }
                isValid={isnewPlanNameValid}
                required
                placeholder="Plan Name"
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  renderDashboardSummaryContainer = () => {
    const { dashboard, isOutputResponseLoading } = this.props;
    return (
      <DashboardSummaryTabsContainer
        dashboard={dashboard}
        isOutputResponseLoading={isOutputResponseLoading}
      />
    );
  };
  convertToDecimal = () => {
    const { derivedInvMarketExpectations } = this.state;
    const newDerivedInvMarketExpectations = derivedInvMarketExpectations.map(
      (value) => {
        let newInvReturnPercentValue = value[1] && value[1].invReturn;
        let newInvVolatilityPercentValue = value[1] && value[1].invVolatility;
        let newInvDividendPercentValue = value[1] && value[1].invDividend;
        if (newInvReturnPercentValue !== '') {
          newInvReturnPercentValue = parseFloat(newInvReturnPercentValue) / 100;
        }
        if (newInvVolatilityPercentValue !== '') {
          newInvVolatilityPercentValue =
            parseFloat(newInvVolatilityPercentValue) / 100;
        }
        if (newInvDividendPercentValue !== '') {
          newInvDividendPercentValue =
            parseFloat(newInvDividendPercentValue) / 100;
        }
        return {
          ...value,
          1: {
            ...value[1],
            invReturn: newInvReturnPercentValue,
            invVolatility: newInvVolatilityPercentValue,
            invDividend: newInvDividendPercentValue,
          },
        };
      }
    );
    return newDerivedInvMarketExpectations;
  };

  handleSave = async () => {
    const {
      derivedInvMarketExpectations,
      isMarketForecastChecked,
      derivedInvMarketCorrelations,
    } = this.state;
    const {
      invMarketCorrelations: {
        productId = '',
        companyName: correlationsCompanyName = '',
      },
      userDetails: loggedInUserDetails = {},
      invMarketExpectationsByAdvisorId: { companyName },
      triggerGetInvMarketExpectationsByAdvisorId,
      selectedPlanId,
      selectedClientId,
      triggerGetInvMarketCorrelationsByAdvisorId,
    } = this.props;
    const { emailId } = loggedInUserDetails;
    if (isMarketForecastChecked) {
      // save market forecast
      const tempDerivedInvMarketExpectations = this.convertToDecimal();
      if (
        !isEqual(tempDerivedInvMarketExpectations, derivedInvMarketExpectations)
      ) {
        this.setState({
          isUpdateInvMarketExpectationsLoading: true,
        });
        let saveRequestObj = tempDerivedInvMarketExpectations.reduce(function (
          acc,
          curr
        ) {
          return { ...acc, [curr[0]]: curr[1] };
        },
          {});
        saveRequestObj = {
          ...saveRequestObj,
          companyName: companyName,
          advisorId: emailId,
        };
        try {
          const [response] = await Promise.all([
            updateInvMarketExpectationsByAdvisorId(saveRequestObj),
          ]);
          if (response && response.status === 200) {
            if (selectedPlanId && selectedClientId) {
              this.getSelectedPlanIdDetails();
            }
            triggerGetInvMarketExpectationsByAdvisorId(emailId);
            this.setState({
              isUpdateInvMarketExpectationsLoading: false,
              isApiFailed: false,
              isInvMarketSnackbarOpen: true,
            });
          } else {
            triggerGetInvMarketExpectationsByAdvisorId(emailId);
            this.setState({
              isUpdateInvMarketExpectationsLoading: false,
              isApiFailed: true,
              isInvMarketSnackbarOpen: true,
            });
          }
        } catch (e) {
          triggerGetInvMarketExpectationsByAdvisorId(emailId);
          this.setState({
            isUpdateInvMarketExpectationsLoading: false,
            isApiFailed: true,
            isInvMarketSnackbarOpen: true,
          });
        }
      }
    } else {
      this.setState({
        isUpdateInvMarketCorrelationsLoading: true,
      });
      const correlations = derivedInvMarketCorrelations.flatMap((item) => {
        return item.details.map((detail) => ({
          asset1: item.label, // The label of the dropdownData
          asset2: detail.subAssetClass, // The subAssetClass from the details
          corr: detail.corrCoeff, // The corrCoeff from the details
        }));
      });

      //save correlations
      const reqObj = {
        advisorId: emailId,
        companyName: '',
        productId: '',
        correlations,
      };
      try {
        const [response] = await Promise.all([
          updateInvMarketCorrelationsByAdvisorId(reqObj),
        ]);
        if (response && response.status === 200) {
          if (selectedPlanId && selectedClientId) {
            this.getSelectedPlanIdDetails();
          }
          triggerGetInvMarketCorrelationsByAdvisorId(emailId);
          this.setState({
            isUpdateInvMarketCorrelationsLoading: false,
            isApiFailed: false,
            isInvMarketCorrelationsSnackbarOpen: true,
          });
        } else {
          triggerGetInvMarketCorrelationsByAdvisorId(emailId);
          this.setState({
            isUpdateInvMarketCorrelationsLoading: false,
            isApiFailed: true,
            isInvMarketCorrelationsSnackbarOpen: true,
          });
        }
      } catch (e) {
        triggerGetInvMarketCorrelationsByAdvisorId(emailId);
        this.setState({
          isUpdateInvMarketCorrelationsLoading: false,
          isApiFailed: true,
          isInvMarketCorrelationsSnackbarOpen: true,
        });
      }
    }
  };

  handleBlur = (event, pKey, field) => {
    const { value } = event && event.target;
    const { derivedInvMarketExpectations } = this.state;
    let newCustValue = value;
    if (percentageFields.includes(field)) {
      if (!value.toString().includes('%')) {
        const percentCustVal = parseFloat(value).toFixed(1);
        newCustValue = `${percentCustVal}%`;
      }
    }
    let tempDerivedInvMarketExpectations = [...derivedInvMarketExpectations];
    const index = tempDerivedInvMarketExpectations.indexOf(
      tempDerivedInvMarketExpectations.filter((f) => f[0] === pKey)[0]
    );
    let innerObj = { ...tempDerivedInvMarketExpectations[index][1] };
    innerObj[field] = newCustValue;
    tempDerivedInvMarketExpectations[index][1] = { ...innerObj };
    this.setState((prevState) => ({
      ...prevState,
      derivedInvMarketExpectations: [...tempDerivedInvMarketExpectations],
      isFormValid: newCustValue.includes('NaN') ? false : true,
    }));
  };

  handleFormFieldChange = (e, pKey, sKey) => {
    const { derivedInvMarketExpectations } = this.state;
    let { value } = e.target;
    if (value.toString().includes('%')) {
      const percentFieldValue = value.split('%')[0];
      value = parseFloat(percentFieldValue);
    } else {
      value = value !== '' && !isNaN(value) ? value : '';
    }
    let tempDerivedInvMarketExpectations = [...derivedInvMarketExpectations];
    const index = tempDerivedInvMarketExpectations.indexOf(
      tempDerivedInvMarketExpectations.filter((f) => f[0] === pKey)[0]
    );
    let innerObj = { ...tempDerivedInvMarketExpectations[index][1] };
    innerObj[sKey] = value;
    tempDerivedInvMarketExpectations[index][1] = { ...innerObj };
    this.setState((prevState) => ({
      ...prevState,
      derivedInvMarketExpectations: [...tempDerivedInvMarketExpectations],
      isFormValid: value === 'NaN' ? false : true,
    }));
  };

  handleSimulate = (timeLineChecked) => {
    const {
      planDetailsByPlanId,
      setInitialReportOptions,
      triggerSimulatePlanDetailsByPlanId,
    } = this.props;
    setInitialReportOptions();
    let tempPlanDetailsByPlanId = { ...planDetailsByPlanId };
    const { moduleSettings: { model = {} } = {}, moduleSettings = {} } =
      tempPlanDetailsByPlanId;
    let tempModel = { ...model, runCode: timeLineChecked ? 0 : 100 }; // setting runcode to 0 for onload for timeline and 100 to networth
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleSettings: {
        ...moduleSettings,
        model: { ...tempModel },
      },
    };
    tempPlanDetailsByPlanId = transformBackToAPIType(tempPlanDetailsByPlanId);
    triggerSimulatePlanDetailsByPlanId({
      displayTermChanged: false,
      chrono: {},
      plannerFormReq: tempPlanDetailsByPlanId,
      version: 'v2',
    });
    this.setState({ isSimulateCalled: true });
  };

  getSelectedPlanIdDetails = () => {
    const { selectedPlanId: planId, getSavedSelectedPlanIdDetails } =
      this.props;
    const version = 'v2';
    if (planId && planId !== '') {
      getSavedSelectedPlanIdDetails({ planId, version });
    }
    this.setState({ isSimulateCalled: false });
  };

  transformValue = (value, field) => {
    if (value && value !== '') {
      if (percentageFields.includes(field)) {
        let percentFieldValue = '';
        if (isNaN(value) && value.includes('%')) {
          percentFieldValue = parseFloat(
            parseFloat(value.split('%')[0]) % 100
          ).toFixed(0);
        } else {
          percentFieldValue = parseFloat(value * 100).toFixed(1);
        }
        return `${percentFieldValue}%`;
      } else {
        let numVal = value;
        if (isNaN(numVal) && numVal.includes('$')) {
          numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
        }
        const numFormat = Intl.NumberFormat('en-US', { notation: 'compact' });
        const newNumformat = numFormat.format(numVal);
        return field === 'assetMix' ? `${newNumformat}` : `$${newNumformat}`;
      }
    }
  };

  transformValue1 = (value, type) => {
    if (value && value !== '') {
      if (type === 'percent') {
        let percentFieldValue = '';
        if (isNaN(value) && value.includes('%')) {
          percentFieldValue = parseFloat(
            parseFloat(value.split('%')[0]) % 100
          ).toFixed(0);
        } else {
          percentFieldValue = parseFloat(value * 100).toFixed(1);
        }
        return `${percentFieldValue}%`;
      } else {
        let numVal = value;
        if (isNaN(numVal) && numVal.includes('$')) {
          numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
        }
        const numFormat = Intl.NumberFormat('en-US');
        const newNumformat = numFormat.format(numVal);
        return `$${newNumformat}`;
      }
    } else {
      if (type === 'percent') {
        return `${0}%`;
      } else {
        return `$${0}`;
      }
    }
  };

  handleMarketValuesEditClick = (type, i, value) => {
    this.setState(
      {
        selectedBlock: i,
        selectedType: type,
        marketValue: value,
      },
      () => {
        const { current = {} } = this.marketValueField;
        if (current) {
          current.focus();
        }
      }
    );
  };

  handleChangeOnMarket = (event) => {
    this.setState({ marketValue: event.target.value });
  };
  handleBlurOnMarket = (e, d) => {
    const { derivedInvMarketExpectations, marketValue, selectedType } =
      this.state;
    const value = marketValue !== '' ? marketValue : '';
    let tempDerivedInvMarketExpectations = [...derivedInvMarketExpectations];
    if (value !== '' && !isNaN(value)) {
      const type =
        selectedType === 'Ret'
          ? 'invReturn'
          : selectedType === 'Vol'
            ? 'invVolatility'
            : 'invDividend';
      const index = tempDerivedInvMarketExpectations.indexOf(
        tempDerivedInvMarketExpectations.filter((f) => f[0] === d[0])[0]
      );
      let innerObj = { ...tempDerivedInvMarketExpectations[index][1] };
      innerObj[type] = parseFloat(parseFloat(value).toFixed(1));
      tempDerivedInvMarketExpectations[index][1] = { ...innerObj };
    }
    this.setState((prevState) => ({
      ...prevState,
      derivedInvMarketExpectations: [...tempDerivedInvMarketExpectations],
      selectedBlock: undefined,
      selectedType: undefined,
    }));
  };

  getColor = (color) => {
    switch (color && color.toLowerCase()) {
      case 'green':
        return '#00A36C';
      case 'lightgreen':
        return '#90EE90';
      case 'red':
        return '#C70039';
      case 'orange':
        return '#FFC300';
      case 'yellow':
        return yellow[600];
      default:
        return grey[600];
    }
  };

  generateChartData = (milestones, heading) => {
    let chartOptions = [];
    if (Array.isArray(milestones)) {
      chartOptions = milestones.filter((m) => m.year !== 0 && m.year !== null);
      chartOptions = chartOptions.sort((a, b) => a.year - b.year);
      chartOptions = chartOptions.map((m, index) => {
        return {
          name: m.name + '<br/>' + m.year + `${m.desc}`,
          color: this.getColor(m.healthFlag ? m.healthFlag : 'grey'),
          dataLabels: {
            color: '#000000',
            backgroundColor: '#ffffff',
            connectorWidth: 2,
            style: {
              textOutline: 0,
            },
          },
        };
      });
    }
    return setTimelineChartOptions(chartOptions, heading);
  };

  toggleTimelineToNetworth = (e) => {
    const { checked: netWorthChecked } = e.target;
    // const { planDetailsByPlanId, triggerUpdatePlannerDetailsinRedux } =
    //   this.props;
    // const { moduleSettings: { model = {} } = {}, moduleSettings = {} } =
    //   planDetailsByPlanId;
    // const tempModel = { ...model, runCode: netWorthChecked ? 100 : 0 }; // setting runcode to 0 for onload for timeline and 100 to networth
    // const tempPlanDetailsByPlanId = {
    //   ...planDetailsByPlanId,
    //   moduleSettings: {
    //     ...moduleSettings,
    //     model: { ...tempModel },
    //   },
    // };
    // triggerUpdatePlannerDetailsinRedux({
    //   plannerData: tempPlanDetailsByPlanId,
    // });
    this.setState({
      timeLineChecked: !netWorthChecked,
    });
  };

  isPlanNameValid = () => {
    const { isnewPlanNameValid } = this.state;
    const { selectedClientId } = this.props;
    const isValid = selectedClientId && isnewPlanNameValid ? true : false;
    return !isValid;
  };

  isClientFormValid = () => {
    const {
      secondPerson,
      clientEmailId,
      isfirstPersonValid,
      issecondPersonValid,
      isclientEmailIdValid,
      clientMobileNumber,
      isclientMobileNumberValid,
      isnewPlanNameValid,
      selectedClientType,
      selectedClientState,
    } = this.state;
    const { clientsNotUpdated } = this.props;
    const isClientExists = clientsNotUpdated.some(
      (client) => client.clientId === clientEmailId
    );
    return !(
      isfirstPersonValid &&
      (secondPerson ? issecondPersonValid : true) &&
      clientEmailId &&
      isclientEmailIdValid &&
      clientMobileNumber &&
      isclientMobileNumberValid &&
      isnewPlanNameValid &&
      selectedClientType &&
      selectedClientState &&
      !isClientExists
    );
  };

  takeAction = (e, cta) => {
    let tabName = 'Insurance';
    tabName = cta?.headingName?.toLowerCase().includes('investments')
      ? 'Investments'
      : cta?.headingName?.toLowerCase().includes('net worth')
        ? 'Goals'
        : cta?.headingName?.toLowerCase().includes('debt')
          ? 'Debt'
          : cta?.headingName?.toLowerCase().includes('expenses')
            ? 'Expenses'
            : tabName;
    history.push({
      pathname: '/solutions',
      state: tabName,
    });
  };

  onClickAssets = () => {
    this.setState({
      activeCard: 'assets',
      selectedMeetingId: '',
    });
  }

  onClickClientReview = () => {
    this.setState({
      activeCard: 'clients',
      selectedMeetingId: '',
    });
  }

  onClickMeetings = () => {
    this.setState({
      activeCard: 'meetings',
      isEditable: false,
      isAddMeeting: false,
      selectedMeetingId: '',
    });
  }

  toggleAllClientAgeStats = (e) => {
    const { checked: allClientAgeStatsChecked } = e.target;
    this.setState({ allClientAgeStats: !allClientAgeStatsChecked });
  };

  toggleRiskLevelToAum = (e) => {
    const { checked: aumChecked } = e.target;
    this.setState({
      isRecommendedRiskLevelChecked: !aumChecked,
    });
  };

  toggleCorrelations = (e) => {
    const { checked: correlationsChecked } = e.target;
    this.setState({
      isMarketForecastChecked: !correlationsChecked,
    });
  };

  transformPercentValueToUI = (val) => {
    let fieldValue = val;
    if (val !== null && val !== undefined && val !== '' && val >= 0) {
      if (val.toString().includes('%')) {
        const percentFieldValue = val.split('%')[0];
        fieldValue =
          percentFieldValue !== '' && !isNaN(percentFieldValue)
            ? parseFloat(percentFieldValue) / 100
            : 0;
      }
      fieldValue = parseFloat(parseFloat(fieldValue) * 100).toFixed(2);
      return fieldValue;
    }
    return 0.0;
  };

  deriveRecommendedRiskLevelValues = (selectedRecommendedRiskAppetite = '') => {
    const { invAssetAllocations } = this.props;
    const transformAssetMixData = (allocation) => {
      return assetMixListValues.map(({ key, label }) => {
        const asset = allocation[key] || {
          assetClass: label,
          assetPercent: 0,
          assetKey: key,
          productId: allocation.productId || '',
        };
        return {
          assetClass: asset.assetClass,
          assetPercent: this.transformPercentValueToUI(asset.assetPercent),
          assetKey: key,
          productId: allocation.productId,
        };
      });
    };

    const selectedRiskLevelObj = invAssetAllocations.find(
      (f) => f.productId === selectedRecommendedRiskAppetite
    );
    let assetMixData = selectedRiskLevelObj
      ? transformAssetMixData(selectedRiskLevelObj)
      : [];

    const allData = invAssetAllocations.map((i) => ({
      productId: i.productId,
      data: transformAssetMixData(i),
    }));

    this.setState({
      assetMixData,
      selectedRecommendedRiskAppetite,
      allData,
      isAssetMixSaveDisabled: false,
    });
  };

  handleChangeOnAssetMixValue = (event) => {
    this.setState({ assetMixValue: event.target.value });
  };

  handleBlurOnAssetMixValue = (e, d) => {
    const {
      assetMixData,
      assetMixValue,
      selectedRecommendedRiskAppetite,
      allData,
    } = this.state;
    const value =
      assetMixValue !== '' && !isNaN(assetMixValue)
        ? Number(assetMixValue).toFixed(2)
        : '';

    // Update assetMixData
    const updatedAssetMixData = assetMixData.map((item) => {
      if (item.assetClass === d.assetClass) {
        return { ...item, assetPercent: value };
      }
      return item;
    });

    const sum = updatedAssetMixData.reduce(
      (acc, item) => acc + parseFloat(item.assetPercent || 0),
      0
    );

    const updatedAllData = allData.map((item) => {
      if (item.productId === selectedRecommendedRiskAppetite) {
        return { ...item, data: updatedAssetMixData };
      }
      return item;
    });

    this.setState((prevState) => ({
      ...prevState,
      assetMixData: updatedAssetMixData,
      selectedAssetMix: undefined,
      isAssetMixSaveDisabled: sum !== 100,
      sumOfAssetMixValue: sum,
      allData: updatedAllData,
    }));
  };

  handleChangeOnCorrelationValue = (event) => {
    this.setState({ selectedCorrelationCoeffValue: event.target.value });
  };

  updateCorrCoeff = (newCorrCoeff, d) => {
    const { subAssetClass, corrCoeff, subKey } = d || {};
    const { derivedInvMarketCorrelations, selectedCorrelation } = this.state;
    // Find the index of the asset class to update
    const correlationIndex = derivedInvMarketCorrelations.findIndex(
      (item) => item.value === selectedCorrelation
    );
    const symmetricalCorrelationIndex = derivedInvMarketCorrelations.findIndex(
      (item) => item.value === subKey
    );
    // Update the dropdownData with the new details array
    const updatedDerivedInvMarketCorrelations = [
      ...derivedInvMarketCorrelations,
    ];
    if (correlationIndex !== -1) {
      // Clone the details array to avoid mutating the original data
      const updatedDetails = derivedInvMarketCorrelations[
        correlationIndex
      ].details.map((detail) => {
        if (detail.subKey === subKey) {
          // Update the corrCoeff value for the matching subAssetClass
          return { ...detail, corrCoeff: newCorrCoeff };
        }
        return detail;
      });
      updatedDerivedInvMarketCorrelations[correlationIndex] = {
        ...updatedDerivedInvMarketCorrelations[correlationIndex],
        details: updatedDetails,
      };
    } else {
      console.error(`Correlation ${selectedCorrelation} not found.`);
    }
    if (symmetricalCorrelationIndex !== -1) {
      // Clone the details array to avoid mutating the original data
      const symmetricalCorrelationUpdatedDetails =
        updatedDerivedInvMarketCorrelations[
          symmetricalCorrelationIndex
        ].details.map((detail) => {
          if (detail.subKey === selectedCorrelation) {
            // Update the corrCoeff value for the matching subAssetClass
            return { ...detail, corrCoeff: newCorrCoeff };
          }
          return detail;
        });
      updatedDerivedInvMarketCorrelations[symmetricalCorrelationIndex] = {
        ...updatedDerivedInvMarketCorrelations[symmetricalCorrelationIndex],
        details: symmetricalCorrelationUpdatedDetails,
      };
    } else {
      console.error(`Correlation ${subKey} not found.`);
    }
    return updatedDerivedInvMarketCorrelations;
  };

  handleBlurOnCorrelationValue = (e, d) => {
    const { selectedCorrelationCoeffValue, selectedCorrelation } = this.state;
    const corrCoeffValue =
      selectedCorrelationCoeffValue !== '' &&
        !isNaN(selectedCorrelationCoeffValue)
        ? Number(selectedCorrelationCoeffValue)
        : '';
    const updatedDerivedInvMarketCorrelations = this.updateCorrCoeff(
      corrCoeffValue,
      d
    );
    const correlationInfo = updatedDerivedInvMarketCorrelations.find(
      (c) => c.value === selectedCorrelation
    );
    this.setState({
      derivedInvMarketCorrelations: updatedDerivedInvMarketCorrelations,
      selectedCorrelationIndex: undefined,
      correlationInfo,
    });
  };

  percentageConversionToAPI = (value) => {
    let valueToApi = '';
    if (value !== null && value !== undefined && value !== '') {
      if (isNaN(value) && value.includes('%')) {
        const percentValue = value.split('%')[0];
        if (!isNaN(percentValue)) {
          valueToApi = parseFloat(parseFloat(percentValue) / 100);
        } else {
          valueToApi = 0;
        }
      } else if (!isNaN(value)) {
        valueToApi = parseFloat(parseFloat(value) / 100);
      }
    } else {
      valueToApi = 0;
    }
    return valueToApi;
  };

  handleSaveAssetMix = async () => {
    const { assetMixData, selectedRecommendedRiskAppetite, allData } =
      this.state;
    const {
      triggerGetInvAssetAllocationsByAdvisorId,
      invAssetAllocations,
      selectedPlanId,
      selectedClientId,
      userDetails: { emailId },
    } = this.props;
    if (allData && allData.length > 0) {
      this.setState({
        isUpdateInvAssetAllocationsLoading: true,
      });
      try {
        // Process each item in allData
        for (const f of allData) {
          const selectedRiskLevelObj = invAssetAllocations.find(
            (i) => i.productId === f.productId
          );
          if (
            selectedRiskLevelObj &&
            Object.keys(selectedRiskLevelObj).length > 0
          ) {
            const reqObj = {
              advisorId: emailId,
              companyName: '',
              riskAppetite: selectedRiskLevelObj.riskAppetite,
              riskLevel: selectedRiskLevelObj.riskLevel,
            };
            f.data.forEach((m) => {
              reqObj[m.assetKey] = this.percentageConversionToAPI(
                m.assetPercent
              );
            });
            const response =
              await updateInvAssetAllocationsByRiskLevelByAdvisorId(reqObj);
            if (response && response.status === 200) {
              continue;
            } else {
              throw new Error('Failed to update asset allocations');
            }
          }
        }
        if (selectedPlanId && selectedClientId) {
          await this.getSelectedPlanIdDetails();
        }
        await triggerGetInvAssetAllocationsByAdvisorId(emailId);
        this.setState({
          isUpdateInvAssetAllocationsLoading: false,
          isApiFailed: false,
          isAssetMixSnackbarOpen: true,
        });
      } catch (e) {
        await triggerGetInvAssetAllocationsByAdvisorId(emailId);
        this.setState({
          isUpdateInvAssetAllocationsLoading: false,
          isApiFailed: true,
          isAssetMixSnackbarOpen: true,
        });
      }
    }
  };

  handleInvMarketCorrelationEditClick = (i, value) => {
    this.setState(
      {
        selectedCorrelationIndex: i,
        selectedCorrelationCoeffValue: value,
      },
      () => {
        const { current = {} } = this.correlationValueField;
        if (current) {
          current.focus();
        }
      }
    );
  };

  handleAssetMixValuesEditClick = (i, value) => {
    this.setState(
      {
        selectedAssetMix: i,
        assetMixValue: value,
      },
      () => {
        const { current = {} } = this.assetMixField;
        if (current) {
          current.focus();
        }
      }
    );
  };

  onRecommendedRiskLevelChange = (event) => {
    let { value: selectedRecommendedRiskAppetite } = event.target;
    this.deriveRecommendedRiskLevelValues(selectedRecommendedRiskAppetite);
  };

  render() {
    const {
      clientOnboardingModalOpen,
      planOnboardingModalOpen,
      firstPerson,
      secondPerson,
      newPlanName,
      isApiFailed,
      openSnackbar,
      openPlanSnackbar,
      isInvMarketSnackbarOpen,
      isInvMarketCorrelationsSnackbarOpen,
      isAssetMixSnackbarOpen,
      isUpdateInvMarketExpectationsLoading,
      isUpdateInvMarketCorrelationsLoading,
      derivedInvMarketExpectations,
      selectedBlock,
      selectedType,
      marketValue,
      activeCard,
      timeLineChecked,
      allClientAgeStats,
      selectedMeetingId,
      loading,
      isDirectUser,
      isClientUser,
      isRecommendedRiskLevelChecked,
      isMarketForecastChecked,
      recommendedRiskLevels,
      selectedRecommendedRiskAppetite,
      assetMixData,
      assetMixValue,
      selectedAssetMix,
      isUpdateInvAssetAllocationsLoading,
      isAssetMixSaveDisabled,
      sumOfAssetMixValue,
      derivedInvMarketCorrelations,
      selectedCorrelation,
      selectedCorrelationCoeffValue,
      selectedCorrelationIndex,
      correlationInfo,
    } = this.state;
    const {
      userDetails: loggedInUserDetails = {},
      isAdvisorAssetsLoading,
      advisorAssetsUnderManagement,
      selectedClientId,
      selectedPlanId,
      plans,
      advisorHome = {},
      charts = [],
      clientsNotUpdated,
      clientMeetings,
      isClientsNotUpdatedLoading,
      isClientMeetingsLoading,
      tenure,
      clientTenure,
      triggerSetTenure,
      triggerSetClientTenure,
      companyAUM: { aum: companyAUM = '' } = {},
      advisorAssetsUnderManagement: { aum = '' },
      allMenuItems,
      isInvAssetAllocationsLoading,
    } = this.props;
    const finalClientsNotUpdated = clientsNotUpdated.filter(
      (f) => f.clientId !== '' && f.clientName !== ''
    );
    const finalClientMeetings = clientMeetings.filter(
      (f) => f.meetingId !== '' && f.meetingName !== ''
    );
    const { milestonesTab = {}, ctas = [] } = advisorHome || {};
    const { milestones = {}, heading: outerHeading = '' } = milestonesTab;
    let timelineChartData = {};
    // if (milestones != null) {
    const { event = [], heading = '' } = milestones;
    // if (event && event.length > 0) {
    timelineChartData = this.generateChartData(event, heading);
    //}
    //}
    const sortedCTAs = ctas.sort((a, b) => {
      return a.position - b.position;
    });
    let firstRowCTAs = sortedCTAs.slice(0, 1);
    firstRowCTAs = firstRowCTAs.map((f) => {
      return {
        ...f,
        headingName: f.heading?.includes(':')
          ? f.heading?.split(':')[0]
          : f.heading || '',
        headingValue: f.heading?.includes(':')
          ? f.heading?.split(':')[1].trim()
          : '',
      };
    });
    let secondRowCTAs = sortedCTAs.slice(1);
    secondRowCTAs = secondRowCTAs.map((f) => {
      return {
        ...f,
        headingName: f.heading?.includes(':')
          ? f.heading?.split(':')[0]
          : f.heading || '',
        headingValue: f.heading?.includes(':')
          ? f.heading?.split(':')[1].trim()
          : '',
      };
    });
    let assetMixRows =
      advisorAssetsUnderManagement &&
        Object.keys(advisorAssetsUnderManagement).length > 0 &&
        advisorAssetsUnderManagement.hasOwnProperty('assetMix')
        ? Object.values(advisorAssetsUnderManagement.assetMix)
        : [];
    assetMixRows = assetMixRows.map((m, i) => {
      return {
        id: i,
        assetType: m.assetLabel,
        assetValue: this.transformValue(m.assetValue, 'assetMix'),
        assetActualValue: m.assetValue,
        assetPercent: m.assetPercent,
      };
    });
    const allClientTypeAgeStatsData =
      allClientAgeStats &&
        advisorAssetsUnderManagement &&
        Object.keys(advisorAssetsUnderManagement).length > 0 &&
        advisorAssetsUnderManagement.hasOwnProperty('allClientAgeStats')
        ? Object.values(advisorAssetsUnderManagement.allClientAgeStats)
        : [];
    const clientTypeAgeStatsData =
      advisorAssetsUnderManagement &&
        Object.keys(advisorAssetsUnderManagement).length > 0 &&
        advisorAssetsUnderManagement.hasOwnProperty('clientTypeAgeStats')
        ? Object.values(advisorAssetsUnderManagement.clientTypeAgeStats)
        : [];
    const aumDistributionChartOptions = setVariblePieChartOptions(assetMixRows);
    const clientTypeDistributionData =
      advisorAssetsUnderManagement &&
        Object.keys(advisorAssetsUnderManagement).length > 0 &&
        advisorAssetsUnderManagement.hasOwnProperty('clientTypeStats')
        ? Object.values(advisorAssetsUnderManagement.clientTypeStats)
        : [];
    const clientTypeDistributionOptions = setRadialCircularChartOptions(
      clientTypeDistributionData
    );
    const ageDistributionOptions = allClientAgeStats
      ? setSpiderChartOptions(allClientTypeAgeStatsData, allClientAgeStats)
      : setSpiderChartOptions(clientTypeAgeStatsData, allClientAgeStats);
    const { userType = '' } = loggedInUserDetails;
    const isClient = userType === USERTYPES.CLIENT;
    const snackbarMsg = openSnackbar
      ? secondPerson
        ? `${firstPerson} & ${secondPerson} client creation is successful`
        : `${firstPerson} client creation is successful`
      : isInvMarketSnackbarOpen ||
        isAssetMixSnackbarOpen ||
        isInvMarketCorrelationsSnackbarOpen
        ? 'Saved Successfully'
        : `${newPlanName} plan creation is successful`;
    const isDirectOrClientUser = isDirectUser || isClientUser;
    return (
      <div className="main-content-wrapper">
        <div className="container-fluid main-content-wrapper-inner">
          {!isDirectOrClientUser && (
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 desktop_card d-flex justify-content-between">
                <div
                  className={`card home_main_card_outer one mb-4 ${activeCard === 'assets' ? `active` : ``
                    }`}
                  onClick={() => {
                    this.setState({
                      activeCard: 'assets',
                      selectedMeetingId: '',
                    });
                  }}
                >
                  <div className="card-body assets_under_management_inner">
                    <h5 className="text-white">Business Review</h5>
                    <div className="Under_Management d-md-flex">
                      <div className="Under_Management_image">
                        <img src={settingDollar} alt="" title="" />
                      </div>
                      {isAdvisorAssetsLoading ? (
                        <CircularProgress
                          sx={{
                            color: '#fff',
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                          }}
                          size={20}
                        />
                      ) : (
                        <div className="Under_Management_content d-md-flex">
                          <div className="Under_Management_content_left">
                            <p>Advisor AUM</p>
                            <h4>
                              <span className="value">
                                {(aum && this.transformValue(aum)) || '$0'}
                              </span>
                            </h4>
                          </div>
                          <div className="Under_Management_content_right">
                            <p>Company AUM</p>
                            <h4>
                              <span className="value">
                                {(companyAUM &&
                                  this.transformValue(companyAUM)) ||
                                  '$0'}
                              </span>
                            </h4>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`card home_main_card_outer three mb-4 ${activeCard === 'clients' ? `active` : ``
                    }`}
                  onClick={() => {
                    this.setState({
                      activeCard: 'clients',
                      selectedMeetingId: '',
                    });
                  }}
                >
                  <div className="card-body client_contact_recency_inner">
                    <h5 className="text-white">Client Review</h5>
                    <div className="Under_Management d-md-flex">
                      <div className="Under_Management_image">
                        <img src={bell} alt="" title="" />
                      </div>
                      <div className="Under_Management_content d-flex">
                        {tenures.map((m) => {
                          return (
                            <div className="Under_Management_content_right">
                              <p
                                className={m.value === tenure ? 'active' : ''}
                                onClick={() => {
                                  triggerSetTenure(m.value);
                                }}
                              >
                                {m.label}
                              </p>
                              <h4>
                                <span className="value">
                                  {m.value === tenure &&
                                    !isClientsNotUpdatedLoading
                                    ? finalClientsNotUpdated.length
                                    : '-'}
                                </span>
                              </h4>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {isClientsNotUpdatedLoading ? (
                      <div className="select_client_outer">
                        <CircularProgress
                          sx={{
                            color: '#fff',
                            position: 'absolute',
                            left: '50%',
                            top: '80%',
                          }}
                          size={20}
                        />
                      </div>
                    ) : (
                      <div className="select_client_outer">
                        <form className="">
                          <div className="select_client_outer_form_row">
                            <div className="client client-review-dd">
                              <div className="Select_Client">
                                <Controls.Select
                                  className="js-example-basic-single"
                                  name="Select_Client"
                                  id="Select_Client"
                                  placeholder="Select Client"
                                  value={selectedClientId}
                                  onChange={(event) =>
                                    this.onClientChange(event)
                                  }
                                  options={finalClientsNotUpdated.map((c) => {
                                    return {
                                      value: c.clientId,
                                      label: c.clientName,
                                    };
                                  })}
                                />
                              </div>
                            </div>
                            <div className="plan client-review-dd">
                              <button
                                type="button"
                                className="common-button"
                                onClick={() =>
                                  this.handleClientOnboardingModalOpen(true)
                                }
                              >
                                <img src={add} alt="" title="" />
                                Client
                              </button>
                              <button
                                type="button"
                                className={
                                  !selectedClientId
                                    ? 'common-button secondary-button'
                                    : 'common-button'
                                }
                                disabled={!selectedClientId}
                                onClick={() =>
                                  this.handleAddPlanModalOpen(true)
                                }
                              >
                                <img src={add} alt="" title="" />
                                Plan
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={`card home_main_card_outer two mb-4 ${activeCard === 'meetings' ? `active` : ``
                    }`}
                  onClick={() => {
                    this.setState({
                      activeCard: 'meetings',
                      isEditable: false,
                      isAddMeeting: false,
                      selectedMeetingId: '',
                    });
                  }}
                >
                  <div className="card-body assets_under_management_inner">
                    <h5 className="text-white">Client Meetings</h5>
                    <div className="Under_Management d-sm-flex">
                      <div className="Under_Management_image">
                        <img src={uilCalender} alt="" title="" />
                      </div>
                      <div className="Under_Management_content d-flex">
                        {clientTenures.map((m) => {
                          return (
                            <div className="Under_Management_content_right">
                              <p
                                className={
                                  m.value === clientTenure ? 'active' : ''
                                }
                                onClick={() => {
                                  triggerSetClientTenure(m.value);
                                  this.setState({ isEditable: false });
                                }}
                              >
                                {m.label}
                              </p>
                              <h4>
                                <span className="value">
                                  {m.value === clientTenure &&
                                    !isClientMeetingsLoading
                                    ? finalClientMeetings.length
                                    : '-'}
                                </span>
                              </h4>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            {activeCard === 'assets' && (
              <AssetsFirstRowSection
                isAdvisorAssetsLoading={isAdvisorAssetsLoading}
                aumDistributionChartOptions={aumDistributionChartOptions}
                clientTypeDistributionOptions={clientTypeDistributionOptions}
                allClientAgeStats={allClientAgeStats}
                ageDistributionOptions={ageDistributionOptions}
                toggleAllClientAgeStats={this.toggleAllClientAgeStats}
                companyAUM={companyAUM}
                advisorAssetsUnderManagement={advisorAssetsUnderManagement}
                transformValue={this.transformValue}
                onClickAssets={this.onClickAssets}
              />
            )}
            {activeCard === 'meetings' && (
              <MeetingsSection
                isClientMeetingsLoading={isClientMeetingsLoading}
                selectedMeetingId={selectedMeetingId}
                finalClientMeetings={finalClientMeetings}
                onClientMeetingChange={this.onClientMeetingChange}
                reminderDays={allMenuItems.reminderDaysMenuItems}
                props={this.props}
                state={this.state}
                handleEditMeeting={this.handleEditMeeting}
                enableMeetingEdit={this.enableMeetingEdit}
                handleMeetingInfoSave={this.handleMeetingInfoSave}
                handleDeleteMeeting={this.handleDeleteMeeting}
                handleAddMeeting={this.handleAddMeeting}
                handleMeetingInfoCancel={this.handleMeetingInfoCancel}
                clientTenure={clientTenure}
                handleBlurToValidate={this.handleBlurToValidate}
                triggerSetClientTenure={triggerSetClientTenure}
                onClickMeetings={this.onClickMeetings}
              />
            )}
            {activeCard === 'clients' && (
              <ClientReviewsFirstRowSection
                firstRowCTAs={firstRowCTAs}
                timeLineChecked={timeLineChecked}
                timelineChartData={timelineChartData}
                toggleTimelineToNetworth={this.toggleTimelineToNetworth}
                charts={charts}
                selectedPlanId={selectedPlanId}
                plans={plans}
                onPlanChange={this.onPlanChange}
                isDirectUser={isDirectUser}
                isDirectOrClientUser={isDirectOrClientUser}
                tenure={tenure}
                triggerSetTenure={triggerSetTenure}
                isClientsNotUpdatedLoading={isClientsNotUpdatedLoading}
                finalClientsNotUpdated={finalClientsNotUpdated}
                selectedClientId={selectedClientId}
                handleAddPlanModalOpen={this.handleAddPlanModalOpen}
                onClientChange={this.onClientChange}
                handleClientOnboardingModalOpen={
                  this.handleClientOnboardingModalOpen
                }
                takeAction={this.takeAction}
                onClickClientReview={this.onClickClientReview}
              />
            )}
          </div>
          <>
            {activeCard === 'assets' && (
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-6 mb-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <Stack direction="row" spacing={1} alignItems="center">
                        <h5 className="mb-0">Recommended Risk Level</h5>
                        <AntSwitch
                          inputProps={{ 'aria-label': 'ant design' }}
                          checked={!isRecommendedRiskLevelChecked}
                          onChange={this.toggleRiskLevelToAum}
                          dual={true}
                        />
                        <h5 className="mb-0">AUM Amounts</h5>
                      </Stack>

                      {isAdvisorAssetsLoading ||
                        isInvAssetAllocationsLoading ||
                        isUpdateInvAssetAllocationsLoading ? (
                        <CircularProgress
                          sx={{
                            color: '#479573',
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                          }}
                          size={20}
                        />
                      ) : isRecommendedRiskLevelChecked ? (
                        <>
                          <div className="Choose_Risk_Appetite_dropdown select_client_outer">
                            <Controls.Select
                              className="js-example-basic-single"
                              name="Moderately_Conservative"
                              id="Moderately_Conservative"
                              value={selectedRecommendedRiskAppetite}
                              onChange={this.onRecommendedRiskLevelChange}
                              options={recommendedRiskLevels.map((t) => {
                                return {
                                  label: t.riskAppetite,
                                  value: t.productId,
                                };
                              })}
                            />
                            <div className="amounts_main_outer">
                              {assetMixData &&
                                assetMixData.map((a, i) => (
                                  <div className="amount_box">
                                    <div className="amount_title">
                                      <p>{a.assetClass}</p>
                                      <EditIcon
                                        className="edit"
                                        sx={{
                                          cursor: 'pointer',
                                          fontSize: '10px',
                                        }}
                                        onClick={() => {
                                          this.handleAssetMixValuesEditClick(
                                            i,
                                            a.assetPercent
                                          );
                                        }}
                                        color="action"
                                        fontSize="sm"
                                      />
                                    </div>
                                    {selectedAssetMix === i ? (
                                      <div className="amount_content">
                                        <input
                                          ref={this.assetMixField}
                                          placeholder=""
                                          type="number"
                                          value={assetMixValue}
                                          onChange={(event) =>
                                            this.handleChangeOnAssetMixValue(
                                              event
                                            )
                                          }
                                          onBlur={(event) =>
                                            this.handleBlurOnAssetMixValue(
                                              event,
                                              a
                                            )
                                          }
                                        />
                                        {'  '} %
                                      </div>
                                    ) : (
                                      <div className="amount_content">
                                        <h6>
                                          {a.assetPercent}
                                          <sup>%</sup>
                                        </h6>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>

                          <div
                            style={{
                              paddingTop: '20px',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              {isAssetMixSaveDisabled && (
                                <Stack sx={{ width: '90%' }}>
                                  <Alert severity="error">
                                    <p>
                                      Asset mix should add upto 100%, currently
                                      it is at {sumOfAssetMixValue}%{' '}
                                    </p>
                                  </Alert>
                                </Stack>
                              )}
                            </div>
                            <div className="save_button">
                              <button
                                type="button"
                                className={
                                  isAssetMixSaveDisabled
                                    ? 'save_btn common-button secondary-button'
                                    : 'save_btn common-button'
                                }
                                variant="contained"
                                onClick={this.handleSaveAssetMix}
                                disabled={isAssetMixSaveDisabled}
                              >
                                <img src={save} alt="" title="" />
                                Save
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="amounts_main_outer">
                          {assetMixRows.map((a) => (
                            <div className="amount_box">
                              <p>{a.assetType}</p>
                              <div className="amount">
                                <span>$</span>
                                {a.assetValue || 0}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-12 col-md-12 mb-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="market_forecast">
                        <div className="market_forecast_box">
                          <div
                            className={
                              isMarketForecastChecked
                                ? 'box_title full-width'
                                : 'box_title'
                            }
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <h5 className="mb-0">
                                MARKET Forecast (Returns, Volatilities &
                                Dividends){' '}
                              </h5>
                              <AntSwitch
                                inputProps={{ 'aria-label': 'ant design' }}
                                checked={!isMarketForecastChecked}
                                onChange={this.toggleCorrelations}
                                dual={true}
                              />
                              <h5 className="mb-0">Correlations</h5>
                            </Stack>
                            <p>
                              These will be used for Cash flow projections in
                              Monte Carlo simulations as well as uniform market
                              simulation. You may Change & Save.
                            </p>
                            <div></div>
                          </div>
                          <>
                            {isMarketForecastChecked ? (
                              <>
                                <div className="market_forecast_box_outer">
                                  {derivedInvMarketExpectations.map((d, i) => (
                                    <div
                                      className={
                                        selectedBlock === i
                                          ? 'box_item edit_ret'
                                          : 'box_item'
                                      }
                                    >
                                      <p className="small_title">
                                        {d[1]?.assetClass}
                                      </p>
                                      <div className="value_outer">
                                        <div className="value_outer_box">
                                          <span className="ret">
                                            Ret
                                            <EditIcon
                                              className="edit"
                                              sx={{
                                                cursor: 'pointer',
                                                fontSize: '10px',
                                              }}
                                              color="action"
                                              fontSize="sm"
                                              onClick={() => {
                                                this.handleMarketValuesEditClick(
                                                  'Ret',
                                                  i,
                                                  d[1]?.invReturn
                                                );
                                              }}
                                            />
                                          </span>
                                          <h6 id="ret">
                                            {d[1]?.invReturn} <sup>%</sup>
                                          </h6>
                                        </div>
                                        <div className="value_outer_box">
                                          <span className="vol">
                                            Vol
                                            <EditIcon
                                              className="edit"
                                              sx={{
                                                fontSize: 12,
                                                cursor: 'pointer',
                                              }}
                                              color="action"
                                              fontSize="sm"
                                              onClick={() => {
                                                this.handleMarketValuesEditClick(
                                                  'Vol',
                                                  i,
                                                  d[1]?.invVolatility
                                                );
                                              }}
                                            />
                                          </span>
                                          <h6 id="vol">
                                            {d[1]?.invVolatility} <sup>%</sup>
                                          </h6>
                                        </div>
                                        {d[1]?.assetClass !== 'Inflation' && (
                                          <div className="value_outer_box">
                                            <span className="div">
                                              Div
                                              <EditIcon
                                                className="edit"
                                                sx={{
                                                  fontSize: 12,
                                                  cursor: 'pointer',
                                                }}
                                                color="action"
                                                fontSize="sm"
                                                onClick={() => {
                                                  this.handleMarketValuesEditClick(
                                                    'Div',
                                                    i,
                                                    d[1]?.invDividend
                                                  );
                                                }}
                                              />
                                            </span>
                                            <h6 id={`div-${i}`}>
                                              {d[1]?.invDividend} <sup>%</sup>
                                            </h6>
                                          </div>
                                        )}
                                      </div>
                                      {selectedBlock === i && (
                                        <div className="value_ret">
                                          <div className="value_ret_content">
                                            <span>{selectedType}</span>
                                            <div className="edit_div">
                                              <input
                                                ref={this.marketValueField}
                                                placeholder=""
                                                type="number"
                                                value={marketValue}
                                                onChange={(event) =>
                                                  this.handleChangeOnMarket(
                                                    event
                                                  )
                                                }
                                                onBlur={(event) =>
                                                  this.handleBlurOnMarket(
                                                    event,
                                                    d
                                                  )
                                                }
                                              />
                                              {'  '} %
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="Dropdown_main_outer">
                                  <Controls.Select
                                    name="correlationsSelect"
                                    label="Correlations for"
                                    value={selectedCorrelation}
                                    onChange={this.handleCorrelationChange}
                                    options={derivedInvMarketCorrelations.map(
                                      (m) => {
                                        return {
                                          label: m.label,
                                          value: m.value,
                                        };
                                      }
                                    )}
                                  />
                                </div>
                                <div className="Correlations_title">
                                  <h5>Correlations With</h5>
                                </div>
                                <div className="Correlations_main_outer">
                                  {correlationInfo?.details &&
                                    correlationInfo?.details.map((m, i) => (
                                      <div class="Correlations_item">
                                        <h5>{m.subAssetClass}</h5>
                                        <div class="value_outer">
                                          {selectedCorrelationIndex === i ? (
                                            <div className="amount_content">
                                              <input
                                                ref={this.correlationValueField}
                                                placeholder=""
                                                type="number"
                                                value={
                                                  selectedCorrelationCoeffValue
                                                }
                                                onChange={(event) =>
                                                  this.handleChangeOnCorrelationValue(
                                                    event
                                                  )
                                                }
                                                onBlur={(event) =>
                                                  this.handleBlurOnCorrelationValue(
                                                    event,
                                                    m
                                                  )
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <h6 className="value">
                                              {m.corrCoeff}
                                            </h6>
                                          )}
                                          {m.subKey !== selectedCorrelation && (
                                            <EditIcon
                                              className="edit"
                                              sx={{
                                                cursor: 'pointer',
                                                fontSize: '10px',
                                              }}
                                              onClick={() => {
                                                this.handleInvMarketCorrelationEditClick(
                                                  i,
                                                  m.corrCoeff
                                                );
                                              }}
                                              color="action"
                                              fontSize="sm"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </>
                            )}
                            {(isUpdateInvMarketExpectationsLoading ||
                              isUpdateInvMarketCorrelationsLoading) && (
                                <CircularProgress
                                  sx={{
                                    color: '#479573',
                                    position: 'relative',
                                    left: '200%',
                                    top: '45%',
                                  }}
                                  size={20}
                                />
                              )}
                          </>
                        </div>
                      </div>
                      <div style={{ paddingTop: '20px' }}>
                        <div className="save_button">
                          <button
                            type="button"
                            className="save_btn"
                            variant="contained"
                            onClick={this.handleSave}
                          >
                            <img src={save} alt="" title="" />
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeCard === 'meetings' && <></>}
            {activeCard === 'clients' && (
              <div className="row">
                {secondRowCTAs && secondRowCTAs.length > 0 ? (
                  secondRowCTAs.map((s, i) => (
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-3 Insurance_div_main_otr">
                      <div className="card Insurance_div">
                        <div className="card-header d-flex align-items-center justify-content-between border-0">
                          <h5 className="mb-0">
                            {s.headingName}
                            {s.headingValue !== '' ? ':' : ''}{' '}
                            {<span>{s.headingValue}</span>}
                          </h5>
                          {!isDirectOrClientUser && (
                            <button
                              type="button"
                              className="btn btn-primary btn-xs"
                              onClick={(e) => this.takeAction(e, s)}
                            >
                              {s.buttonName}
                            </button>
                          )}
                        </div>
                        <div className="card-body Insurance_div text-center">
                          {s.renderWhat && s.renderWhat.tabType === 'chart' ? (
                            <HomeChartComponent cta={s} charts={charts} />
                          ) : (
                            <InfoContainer cta={s} />
                          )}
                        </div>
                        <div className="card-footer">
                          <div className="client_contact_total_outer">
                            <div className="client_contact_total left">
                              {
                                <p>
                                  {s.textBoxLabel2}{' '}
                                  <span className="value">
                                    {s.textBoxLine2}
                                  </span>
                                </p>
                              }
                            </div>
                            <div className="client_contact_total right">
                              <p>
                                {s.textBoxLabel3}{' '}
                                <span className="value">{s.textBoxLine3}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-3 Insurance_div_main_otr">
                      <div className="card Insurance_div"></div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-3 Insurance_div_main_otr">
                      <div className="card Insurance_div"></div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-3 Insurance_div_main_otr">
                      <div className="card Insurance_div"></div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 mb-3 Insurance_div_main_otr">
                      <div className="card Insurance_div"></div>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        </div>
        <Snackbar
          open={
            openSnackbar ||
            openPlanSnackbar ||
            isInvMarketSnackbarOpen ||
            isAssetMixSnackbarOpen ||
            isInvMarketCorrelationsSnackbarOpen
          }
          autoHideDuration={4000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={this.handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {!isApiFailed
              ? snackbarMsg
              : 'Something went wrong, please try again !'}
          </Alert>
        </Snackbar>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          // open={
          //   simulatePlanDetailsLoading || plannerDetailsLoading || isLoading
          // }
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {clientOnboardingModalOpen && (
          <ConfirmModal
            content={this.renderNewClientOnboardingDetails()}
            open={clientOnboardingModalOpen}
            setOpen={this.handleClientOnboardingModalOpen}
            onConfirm={this.handleAddNewClient}
            okLabel="Add"
            from="horizon"
            size="md"
            isOkButtonDisabled={this.isClientFormValid()}
            dialogId="add_client"
          />
        )}
        {selectedClientId !== '' && planOnboardingModalOpen && (
          <ConfirmModal
            content={this.renderNewPlanDetails()}
            open={planOnboardingModalOpen}
            setOpen={this.handleAddPlanModalOpen}
            onConfirm={this.handleNewPlan}
            okLabel="Add"
            from="horizon"
            size="xs"
            isOkButtonDisabled={this.isPlanNameValid()}
            dialogId="add_plan"
          />
        )}
      </div>
    );
  }

  enableMeetingEdit = () => {
    this.setState({
      isEditable: true,
    });
  };

  validateField = (name, value) => {
    let isValid = true;
    switch (name) {
      case 'mobileNumber':
        isValid = validatePhoneNumber(value); // returns true if valid
        break;
      case 'emailId':
        isValid = validateEmailId(value);
        break;
      case 'meetingName':
        isValid = validateNamesWithoutSpaces(value);
        break;
      default:
        isValid = true;
    }
    // Update the corresponding isValid state
    this.setState({
      [`is${name}Valid`]: isValid,
    });
  };

  handleBlurToValidate = (event, fieldName) => {
    const { value } = event.target;
    this.validateField(fieldName, value);
  };

  handleEditMeeting = (event, fieldName, meetingNotes = '') => {
    let meetingAttributes = [
      'firstreminderdays',
      'secondreminderdays',
      'meetingname',
      'commsemail',
      'commssms',
      'commsphone',
      'meetingnotes',
      'meetingstatus',
      'meetingdate',
      'meetingtime',
    ];
    let { localClientDetail, localMeetingDetail } = this.state;
    let tempLocalClientDetail = { ...localClientDetail };
    let tempLocalMeetingDetail = { ...localMeetingDetail };
    if (fieldName.toLowerCase() === 'mobilenumber') {
      const formattedMobileNumber = formatPhoneNumber(event.target.value);
      // localClientDetail[fieldName] = formattedMobileNumber;
      tempLocalClientDetail = {
        ...tempLocalClientDetail,
        [fieldName]: formattedMobileNumber,
      };
      // this.setState({
      //   localClientDetail: {
      //     ...localClientDetail,
      //     [fieldName]: formattedMobileNumber,
      //   },
      // });
    } else if (meetingAttributes.includes(fieldName.toLowerCase())) {
      // let { localMeetingDetail } = this.state;
      if (
        ['commsemail', 'commssms', 'commsphone'].includes(
          fieldName.toLowerCase()
        )
      ) {
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          [fieldName]: event.target.checked,
        };
        //localMeetingDetail[fieldName] = event.target.checked;
      } else if (fieldName.toLowerCase() === 'meetingstatus') {
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          [fieldName]: event.target.checked
            ? 'Concluded'
            : tempLocalMeetingDetail[fieldName],
        };
        // localMeetingDetail[fieldName] =
        //   event.target.checked === true
        //     ? 'Concluded'
        //     : localMeetingDetail[fieldName];
      } else if (fieldName.toLowerCase() === 'meetingnotes') {
        let { meetingNotes: tempMeetingNotes = [] } =
          tempLocalMeetingDetail || {};
        // if (
        //   localMeetingDetail?.meetingNotes === null ||
        //   localMeetingDetail?.meetingNotes?.length <= 0
        // ) {
        //   localMeetingDetail.meetingNotes = [];
        // }
        tempMeetingNotes.push(meetingNotes);
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          meetingNotes: tempMeetingNotes,
        };
        //localMeetingDetail.meetingNotes.push(meetingNotes);
      } else if (fieldName.toLowerCase() === 'meetingdate') {
        const formattedDate =
          format(event, "yyyy-MM-dd'T'HH:mm:ss.SSS") + '+0000';
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          [fieldName]: formattedDate,
        };
        //localMeetingDetail[fieldName] = formattedDate;
      } else if (fieldName.toLowerCase() === 'meetingtime') {
        const { meetingDate = '' } = tempLocalMeetingDetail;
        let eventTime = event.$d.toLocaleTimeString('en-US', { hour12: false });
        let datePart = meetingDate.split('T')[0];
        let updatedMeetingDate = datePart + 'T' + eventTime + '.000+0000';
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          meetingDate: updatedMeetingDate,
        };
        //localMeetingDetail['meetingDate'] = updatedMeetingDate;
      } else {
        tempLocalMeetingDetail = {
          ...tempLocalMeetingDetail,
          [fieldName]: event.target.value,
        };
        this.validateField(fieldName, event.target.value);
        // localMeetingDetail[fieldName] = event.target.value;
      }
    }
    this.setState({
      localMeetingDetail: tempLocalMeetingDetail,
      localClientDetail: tempLocalClientDetail,
      // [`is${fieldName}Valid`]: true,
      // backUpLocalMeetingDetail: backUpLocalMeetingDetail,
      // backUpLocalClientDetail: backUpLocalClientDetail,
    });
  };
}

const MeetingsSection = ({
  isClientMeetingsLoading,
  selectedMeetingId,
  finalClientMeetings,
  onClientMeetingChange,
  reminderDays,
  props,
  state,
  handleEditMeeting,
  enableMeetingEdit,
  handleMeetingInfoSave,
  handleDeleteMeeting,
  handleAddMeeting,
  handleMeetingInfoCancel,
  clientTenure,
  triggerSetClientTenure,
  handleBlurToValidate,
  onClickMeetings
}) => {
  const { ismeetingNameValid, ismobileNumberValid } = state;
  let localMeetingDetail = state?.localMeetingDetail;
  let localClientDetail = state?.localClientDetail;
  const dateWithoutOffset = localMeetingDetail?.meetingDate
    ? new Date(
      localMeetingDetail?.meetingDate.replace(
        /(\d\d:\d\d:\d\d\.\d\d\d)[+-]\d{4}/,
        '$1'
      )
    )
    : null;
  const [inputValue, setInputValue] = useState('');
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [nameToDelete, setNameToDelete] = useState('');

  const [isOpenAddPopUpMessage, setIsOpenAddPopUpMessage] = useState(false);

  const openDeleteConfirmation = (name) => {
    setIsDeleteConfirmationOpen(true);
    setNameToDelete(name);
  };

  // Function to close the confirmation dialog
  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const openAddPopUpMessage = () => {
    if (!state.selectedMeetingId) {
      if (props.selectedClientId) {
        setIsOpenAddPopUpMessage(false);
        handleAddMeeting();
      } else {
        setIsOpenAddPopUpMessage(true);
      }
    } else {
      setIsOpenAddPopUpMessage(false);
      handleAddMeeting();
    }
  };

  // Function to close the confirmation dialog
  const closeAddPopUpMessage = () => {
    setIsOpenAddPopUpMessage(false);
  };

  // Function to handle item deletion after confirmation
  const handleDeleteConfirmation = () => {
    closeDeleteConfirmation();
    // Perform the delete action here
    handleDeleteMeeting(selectedMeetingId);
  };

  const validateMeetingAttributes = () => {
    return (
      localMeetingDetail &&
      localClientDetail &&
      localMeetingDetail.meetingName?.length > 0 &&
      localMeetingDetail.meetingDate?.length > 0 &&
      localClientDetail.mobileNumber?.length > 0 &&
      ismeetingNameValid !== false &&
      ismobileNumberValid !== false &&
      (localMeetingDetail.commsEmail?.toString() === 'true' ||
        localMeetingDetail.commsSMS?.toString() === 'true' ||
        localMeetingDetail.commsPhone?.toString() === 'true')
    );
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 mobile_card justify-content-between">
        <div
          className={`card home_main_card_outer two mb-4 active`}
          onClick={() => {
            onClickMeetings()
          }}
        >
          <div className="card-body assets_under_management_inner">
            {/* <h5 className="text-white">Client Meetings</h5> */}
            <div className="Under_Management d-sm-flex">
              {/* <div className="Under_Management_image">
              <img src={uilCalender} alt="" title="" />
            </div> */}
              <div className="Under_Management_content d-flex">
                {clientTenures.map((m) => {
                  return (
                    <div className="Under_Management_content_right">
                      <p
                        className={m.value === clientTenure ? 'active' : ''}
                        onClick={() => {
                          triggerSetClientTenure(m.value);
                        }}
                      >
                        {m.label}
                      </p>
                      <h4>
                        <span className="value">
                          {m.value === clientTenure && !isClientMeetingsLoading
                            ? finalClientMeetings.length
                            : '-'}
                        </span>
                      </h4>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
        <div className="card h-100">
          <div className="card-body">
            <div className="crm_content_main_screen">
              <div className="crm_content">
                {isClientMeetingsLoading ? (
                  <CircularProgress
                    sx={{
                      color: '#479573',
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                    }}
                    size={20}
                  />
                ) : (
                  <>
                    <div className="row">
                      {!state.isAddMeeting && (
                        <RenderSelectMeetingsDropdown
                          selectedMeetingId={selectedMeetingId}
                          finalClientMeetings={finalClientMeetings}
                          onClientMeetingChange={onClientMeetingChange}
                          enableMeetingEdit={enableMeetingEdit}
                          state={state}
                          openDeleteConfirmation={openDeleteConfirmation}
                          openAddPopUpMessage={openAddPopUpMessage}
                        ></RenderSelectMeetingsDropdown>
                      )}
                    </div>
                    {(state.isAddMeeting ||
                      (selectedMeetingId && selectedMeetingId !== '')) && (
                        //&& localMeetingDetail && Object.keys(localMeetingDetail).length > 0
                        <>
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form_group">
                                    <Controls.Input
                                      type="email"
                                      id="clientId"
                                      name="clientId"
                                      label="Client ID"
                                      value={localMeetingDetail?.clientId || ''}
                                      disabled={true}
                                      onChange={(event) =>
                                        handleEditMeeting(event, 'clientId')
                                      }
                                      placeholder="Client Email"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form_group">
                                    <Controls.Input
                                      type="text"
                                      id="clientName"
                                      name="clientName"
                                      label="Client Name"
                                      value={localClientDetail?.name || ''}
                                      disabled={true}
                                      onChange={(event) =>
                                        handleEditMeeting(event, 'name')
                                      }
                                      placeholder="Client Name"
                                      required
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form_group">
                                    {/* <h5 className="mb-0 title-div">
                                      CLIENT MOBILE NUMBER
                                    </h5> */}
                                    <Controls.Input
                                      id="clientMobile"
                                      name="clientMobile"
                                      label="Client Mobile Number"
                                      value={
                                        localClientDetail?.mobileNumber
                                          ? localClientDetail?.mobileNumber
                                          : ''
                                      }
                                      type="text"
                                      onChange={(event) =>
                                        handleEditMeeting(event, 'mobileNumber')
                                      }
                                      disabled={!state.isEditable}
                                      errorText={
                                        localClientDetail?.mobileNumber
                                          ? 'Invalid Mobile Number'
                                          : 'This is a mandatory field'
                                      }
                                      onBlur={(event) =>
                                        handleBlurToValidate(
                                          event,
                                          'mobileNumber'
                                        )
                                      }
                                      isValid={ismobileNumberValid}
                                      required
                                      placeholder="(123) 456-7890"
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form_group">
                                    <Controls.Input
                                      type="text"
                                      id="meetingName"
                                      name="meetingName"
                                      label="Meeting Name"
                                      value={
                                        localMeetingDetail?.meetingName || ''
                                      }
                                      // className={'custom-input'} // Apply the appropriate class
                                      disabled={!state.isEditable}
                                      onChange={(event) =>
                                        handleEditMeeting(event, 'meetingName')
                                      }
                                      onBlur={(event) =>
                                        handleBlurToValidate(event, 'meetingName')
                                      }
                                      placeholder="Meeting Name"
                                      errorText={
                                        ismeetingNameValid
                                          ? ''
                                          : localMeetingDetail?.meetingName
                                            ? 'Invalid Meeting Name, cannot take spaces and numbers'
                                            : 'This is a mandatory field'
                                      }
                                      isValid={ismeetingNameValid}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form_group select_date_time_outer">
                                    <h5 className="mb-0 title-div required">
                                      DATE
                                    </h5>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <DatePicker
                                        className="planner-input long-text-input"
                                        autoOk
                                        closeOnSelect={true}
                                        disablePast={true}
                                        variant="inline"
                                        InputAdornmentProps={{
                                          position: 'start',
                                        }}
                                        value={dateWithoutOffset}
                                        disabled={!state.isEditable}
                                        format="yyyy-MM-dd"
                                        placeholder="YYYY-MM-DD"
                                        onChange={(event) =>
                                          handleEditMeeting(event, 'meetingDate')
                                        }
                                        inputProps={{ style: { height: 'auto' } }}
                                        utc
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form_group select_time_outer">
                                    <h5 className="mb-0 title-div required">
                                      TIME
                                    </h5>
                                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <TimePicker
                                        className="planner-input long-text-input"
                                        autoOk
                                        closeOnSelect={true}
                                        //variant="inline"
                                        //InputAdornmentProps={{ position: 'start' }}
                                        value={dateWithoutOffset}
                                        disabled={!state.isEditable}
                                        onChange={(event) =>
                                          handleEditMeeting(event, 'meetingDate')
                                        }
                                      />
                                    </MuiPickersUtilsProvider> */}
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <TimePicker
                                        className="planner-input long-text-input"
                                        autoOk
                                        closeOnSelect={true}
                                        //variant="inline"
                                        //InputAdornmentProps={{ position: 'start' }}
                                        value={dayjs(dateWithoutOffset)}
                                        disabled={!state.isEditable}
                                        onChange={(event) =>
                                          handleEditMeeting(event, 'meetingtime')
                                        }
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="reminder_div">
                                    <div className="form_group">
                                      <h5 className="mb-0 title-div">
                                        FIRST REMINDER
                                      </h5>
                                      <select
                                        style={{ height: 39 }} // Apply the same class as the input fields
                                        name={'firstReminderDays'}
                                        onChange={(event) =>
                                          handleEditMeeting(
                                            event,
                                            'firstReminderDays'
                                          )
                                        }
                                        value={
                                          localMeetingDetail?.firstReminderDays
                                        }
                                        className={'custom-input'} // Apply the appropriate class
                                        disabled={!state.isEditable}
                                      >
                                        {reminderDays &&
                                          reminderDays.map((option, value) => (
                                            <option
                                              value={option.label}
                                              key={value}
                                            >
                                              {option.value}
                                            </option>
                                          ))}
                                      </select>
                                    </div>

                                    <div className="form_group">
                                      <h5 className="mb-0 title-div">
                                        SECOND REMINDER
                                      </h5>
                                      <select
                                        style={{ height: 39 }} // Apply the same class as the input fields
                                        name={'secondReminderDays'}
                                        onChange={(event) =>
                                          handleEditMeeting(
                                            event,
                                            'secondReminderDays'
                                          )
                                        }
                                        value={
                                          localMeetingDetail?.secondReminderDays
                                        }
                                        className={'custom-input'} // Apply the appropriate class
                                        disabled={!state.isEditable}
                                      >
                                        {reminderDays &&
                                          reminderDays.map((option, value) => (
                                            <option
                                              value={option.label}
                                              key={value}
                                            >
                                              {option.value}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form_group">
                                    <h5 className="mb-0 title-div required">
                                      COMMUNICATION PREFERENCES
                                    </h5>
                                    <div className="Choose_Risk_Appetite_member checkbox">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="emailPreference"
                                            color="primary"
                                            disabled={!state.isEditable}
                                            checked={
                                              localMeetingDetail &&
                                                localMeetingDetail?.commsEmail &&
                                                localMeetingDetail?.commsEmail
                                                  ?.toString()
                                                  .toLowerCase() === 'true'
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              handleEditMeeting(
                                                event,
                                                'commsEmail'
                                              )
                                            }
                                          />
                                        }
                                        label={'Email'}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="SMSPreference"
                                            color="primary"
                                            disabled={!state.isEditable}
                                            checked={
                                              localMeetingDetail &&
                                                localMeetingDetail?.commsSMS &&
                                                localMeetingDetail?.commsSMS
                                                  ?.toString()
                                                  .toLowerCase() === 'true'
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              handleEditMeeting(event, 'commsSMS')
                                            }
                                          />
                                        }
                                        label={'SMS'}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="phonePreference"
                                            color="primary"
                                            disabled={!state.isEditable}
                                            checked={
                                              localMeetingDetail &&
                                                localMeetingDetail?.commsPhone &&
                                                localMeetingDetail?.commsPhone
                                                  ?.toString()
                                                  .toLowerCase() === 'true'
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              handleEditMeeting(
                                                event,
                                                'commsPhone'
                                              )
                                            }
                                          />
                                        }
                                        label={'Phone'}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="save_inline_div">
                                <div className="form_group">
                                  {state.isEditable && (
                                    <div className="save_button">
                                      <button
                                        onClick={(event) => {
                                          handleMeetingInfoCancel();
                                        }}
                                      >
                                        CANCEL
                                      </button>
                                    </div>
                                  )}
                                </div>

                                <div className="form_group">
                                  {state.isEditable && (
                                    <div className={`${'save_button'}`}>
                                      <button
                                        className={`${validateMeetingAttributes()
                                          ? ''
                                          : 'secondary-button'
                                          }`}
                                        disabled={!validateMeetingAttributes()}
                                        onClick={(event) => {
                                          handleMeetingInfoSave();
                                        }}
                                      >
                                        {' '}
                                        <img src={save} alt="" title="" /> SAVE
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row"></div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form_group">
                                <h5 className="mb-0 title-div">Previous notes</h5>
                                <div className="crm_all_notes_outer">
                                  <ul>
                                    {localMeetingDetail?.meetingNotes?.length >
                                      0 &&
                                      localMeetingDetail?.meetingNotes.map(
                                        (note, index) => (
                                          <li key={index}>{note}</li>
                                        )
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form_group new_notes_outer">
                                <h5 className="mb-0 title-div">New Note</h5>
                                <textarea
                                  placeholder="Add Notes for Meeting"
                                  value={inputValue}
                                  disabled={!state.isEditable}
                                  onChange={(event) =>
                                    setInputValue(event.target.value)
                                  }
                                ></textarea>
                                <div className="Choose_Risk_Appetite_member checkbox">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="meetingStatus"
                                        color="primary"
                                        disabled={!state.isEditable}
                                        checked={
                                          localMeetingDetail?.meetingStatus?.toLowerCase() ===
                                            'concluded'
                                            ? true
                                            : false
                                        }
                                        onChange={(event) =>
                                          handleEditMeeting(
                                            event,
                                            'meetingStatus'
                                          )
                                        }
                                      />
                                    }
                                    label={'Meeting Concluded'}
                                  />
                                  {state.isEditable && (
                                    <div className="save_button">
                                      <button
                                        onClick={(event) => {
                                          handleEditMeeting(
                                            event,
                                            'meetingNotes',
                                            inputValue
                                          );
                                          setInputValue('');
                                        }}
                                      >
                                        Add Note
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* ... Add the specific content for meetings ... */}
      </div>
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmation}
        className="delete_popup_outer"
      >
        <DialogTitle>
          Are you sure you want to delete "{nameToDelete}"?
        </DialogTitle>
        <DialogActions>
          <button onClick={closeDeleteConfirmation} className="common-button">
            Cancel
          </button>
          <button onClick={handleDeleteConfirmation} className="common-button">
            Delete
          </button>
        </DialogActions>
      </Dialog>
      {/* Delete Confirmation Dialog */}
      <Dialog open={isOpenAddPopUpMessage} onClose={closeAddPopUpMessage}>
        <DialogTitle>
          Select a client from the client review section/meetings to schedule a
          meeting.
        </DialogTitle>
        <DialogActions>
          <button onClick={closeAddPopUpMessage} className="common-button">
            Ok
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const RenderSelectMeetingsDropdown = ({
  selectedMeetingId,
  finalClientMeetings,
  onClientMeetingChange,
  enableMeetingEdit,
  state,
  openDeleteConfirmation,
  openAddPopUpMessage,
}) => (
  <>
    <div className="col-lg-6 col-8">
      <div className="form_group">
        <h5 className="mb-0 title-div">SELECT MEETING</h5>
        <Controls.Select
          className="js-example-basic-single"
          name="Select_Meeting"
          id="Select_Meeting"
          placeholder="Select Meeting"
          value={selectedMeetingId}
          onChange={(event) => onClientMeetingChange(event)}
          options={finalClientMeetings.map((c) => {
            return {
              value: c.meetingId,
              label: c.mtgDetail,
            };
          })}
        />
      </div>
    </div>
    <div className="col-lg-6 col-4 button-group" style={{ display: 'block' }}>
      <div className="form_group">
        <h5 className="mb-0 title-div">&nbsp;</h5>
        <div className="select_meeting_edit_outer d-flex">
          {!state.isEditable &&
            selectedMeetingId !== undefined &&
            selectedMeetingId !== null &&
            selectedMeetingId !== '' &&
            finalClientMeetings &&
            finalClientMeetings.length > 0 && (
              <button
                className="action-button"
                onClick={(e) => {
                  enableMeetingEdit();
                }}
              >
                <img src={edit_pencil} alt="" title="" />
              </button>
            )}
          {!state.isEditable &&
            selectedMeetingId !== undefined &&
            selectedMeetingId !== null &&
            selectedMeetingId !== '' &&
            finalClientMeetings &&
            finalClientMeetings.length > 0 && (
              <button
                className="action-button"
                onClick={(e) =>
                  openDeleteConfirmation(state?.localMeetingDetail?.meetingName)
                }
              >
                <img src={delete_icon} alt="" title="" />
              </button>
            )}
          {!state.isEditable && (
            <button
              className="action-button"
              onClick={(e) => openAddPopUpMessage()}
            >
              <img src={pdf_page_plus_icon} alt="" title="" />
            </button>
          )}
        </div>
      </div>
    </div>
  </>
);

const ClientReviewsFirstRowSection = ({
  firstRowCTAs,
  timeLineChecked,
  // selectedAllocationId,
  // allocation,
  timelineChartData,
  toggleTimelineToNetworth,
  // onAllocationChange,
  charts,
  selectedPlanId,
  plans,
  onPlanChange,
  isDirectUser,
  isDirectOrClientUser,
  tenure,
  triggerSetTenure,
  isClientsNotUpdatedLoading,
  finalClientsNotUpdated,
  selectedClientId,
  handleClientOnboardingModalOpen,
  onClientChange,
  handleAddPlanModalOpen,
  takeAction,
  onClickClientReview
}) => (
  <>
    <div className="col-lg-12 col-md-12 col-sm-12 mobile_card justify-content-between">
      <div
        className={`card home_main_card_outer three mb-4 active`}
        onClick={() => {
          onClickClientReview()
        }}
      >
        <div className="card-body client_contact_recency_inner">
          <div className="Under_Management d-md-flex">
            <div className="Under_Management_content d-flex">
              {tenures.map((m) => {
                return (
                  <div className="Under_Management_content_right">
                    <p
                      className={m.value === tenure ? 'active' : ''}
                      onClick={() => {
                        triggerSetTenure(m.value);
                      }}
                    >
                      {m.label}
                    </p>
                    <h4>
                      <span className="value">
                        {m.value === tenure && !isClientsNotUpdatedLoading
                          ? finalClientsNotUpdated.length
                          : '-'}
                      </span>
                    </h4>
                  </div>
                );
              })}
            </div>
          </div>
          {isClientsNotUpdatedLoading ? (
            <div className="select_client_outer">
              <CircularProgress
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  left: '50%',
                  top: '80%',
                }}
                size={20}
              />
            </div>
          ) : (
            <div className="select_client_outer">
              <form className="">
                <div className="select_client_outer_form_row">
                  <div className="client client-review-dd">
                    <div className="Select_Client">
                      <Controls.Select
                        className="js-example-basic-single"
                        name="Select_Client"
                        id="Select_Client"
                        placeholder="Select Client"
                        value={selectedClientId}
                        onChange={(event) => onClientChange(event)}
                        options={finalClientsNotUpdated.map((c) => {
                          return {
                            value: c.clientId,
                            label: c.clientName,
                          };
                        })}
                      />
                    </div>
                  </div>
                  <div className="plan client-review-dd">
                    <button
                      type="button"
                      className="common-button"
                      onClick={() => handleClientOnboardingModalOpen(true)}
                    >
                      <img src={add} alt="" title="" />
                      Client
                    </button>
                    <button
                      type="button"
                      className={
                        !selectedClientId
                          ? 'common-button secondary-button'
                          : 'common-button'
                      }
                      disabled={!selectedClientId || selectedClientId === ''}
                      onClick={() => handleAddPlanModalOpen(true)}
                    >
                      <img src={add} alt="" title="" />
                      Plan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>

    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 planOuter">
      {!isDirectUser === true && (
        <div className="select_client_outer select_client_outer_other">
          <div className="select_client_outer_form_row marketandasset_outer d-flex">
            <div className="client">
              <p>Plans</p>
              <Controls.Select
                className="js-example-basic-single"
                name="clientPlans"
                id="clientPlans"
                placeholder="Choose Plan"
                value={selectedPlanId}
                onChange={onPlanChange}
                noOptionsMessage={() => 'Please select client to start with'}
                options={plans.map((p) => {
                  return {
                    value: p.value,
                    label: p.label,
                  };
                })}
              />
            </div>
          </div>
        </div>
      )}
      {firstRowCTAs.length > 0 ? (
        <>
          <div className="card timeline-card">
            <div className="card-header d-flex align-items-center justify-content-between border-0">
              <Stack direction="row" spacing={1} alignItems="center">
                <h5 className="mb-0">Timeline</h5>
                <AntSwitch
                  inputProps={{ 'aria-label': 'ant design' }}
                  checked={!timeLineChecked}
                  onChange={toggleTimelineToNetworth}
                  dual={true}
                />
                <h5 className="mb-0">{firstRowCTAs[0].heading}</h5>
              </Stack>
              {!isDirectOrClientUser && (
                <button
                  className="btn btn-primary btn-xs"
                  type="button"
                  onClick={(e) => takeAction(e, firstRowCTAs[0])}
                >
                  {!timeLineChecked
                    ? firstRowCTAs[0].buttonName
                    : 'Take Action'}{' '}
                </button>
              )}
            </div>
            <div className="card-body Insurance_div text-center">
              {!timeLineChecked ? (
                firstRowCTAs[0].renderWhat &&
                  firstRowCTAs[0].renderWhat.tabType === 'chart' ? (
                  <HomeChartComponent cta={firstRowCTAs[0]} charts={charts} />
                ) : (
                  <InfoContainer cta={firstRowCTAs[0]} />
                )
              ) : (
                <TimelineChartContainer options={timelineChartData} />
              )}
            </div>
            <div className="card-footer">
              <div className="client_contact_total_outer">
                {!timeLineChecked ? (
                  <div className="client_contact_total left">
                    {
                      <p>
                        {firstRowCTAs[0].textBoxLabel2}{' '}
                        <span className="value">
                          {firstRowCTAs[0].textBoxLine2}
                        </span>
                      </p>
                    }
                  </div>
                ) : (
                  <div className="client_contact_total left"></div>
                )}
                {!timeLineChecked ? (
                  <div className="client_contact_total right">
                    <p>
                      {firstRowCTAs[0].textBoxLabel3}{' '}
                      <span className="value">
                        {firstRowCTAs[0].textBoxLine3}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className="client_contact_total right"></div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="card Insurance_div"></div>
        </>
      )}
    </div>
  </>
);

const AssetsFirstRowSection = ({
  isAdvisorAssetsLoading,
  aumDistributionChartOptions,
  clientTypeDistributionOptions,
  allClientAgeStats,
  ageDistributionOptions,
  toggleAllClientAgeStats,
  companyAUM,
  advisorAssetsUnderManagement: { aum = '' },
  transformValue,
  onClickAssets
}) => (
  <>
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mobile_card justify-content-between">
      <div
        className={`card home_main_card_outer one mb-4 active`}
        onClick={() => {
          onClickAssets();
        }}
      >
        <div className="card-body assets_under_management_inner">
          <div className="Under_Management d-md-flex">
            {isAdvisorAssetsLoading ? (
              <CircularProgress
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                }}
                size={20}
              />
            ) : (
              <div>
                <div className="Under_Management_content d-md-flex">
                  <div className="Under_Management_content_left">
                    <p>Advisor AUM</p>
                    <h4>
                      <span className="value">
                        {(aum && transformValue(aum)) || '$0'}
                      </span>
                    </h4>
                  </div>
                  <div className="Under_Management_content_right">
                    <p>Company AUM</p>
                    <h4>
                      <span className="value">
                        {(companyAUM && transformValue(companyAUM)) || '$0'}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
      <div className="card h-100">
        <div className="card-body">
          <h5>AUM Distribution</h5>
          <div className="chart_div">
            {isAdvisorAssetsLoading ? (
              <CircularProgress
                sx={{
                  color: '#479573',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                }}
                size={20}
              />
            ) : (
              <ChartContainer
                chartType="variablepie"
                selectedChart={'aumDistribution'}
                options={aumDistributionChartOptions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
      <div className="card h-100">
        <div className="card-body">
          <div className="h-100">
            <h5>Client TYPE DISTRIBUTION</h5>
            {isAdvisorAssetsLoading ? (
              <CircularProgress
                sx={{
                  color: '#479573',
                  position: 'relative',
                  left: '45%',
                  top: '50%',
                }}
                size={20}
              />
            ) : (
              <ChartContainer
                chartType="radial"
                selectedChart={'clientTypeDistribution'}
                options={clientTypeDistributionOptions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
      <div className="card h-100 mb-0">
        <div className="card-body">
          <div className="client_age_distribution_outer">
            <h5>Client age distribution (%)</h5>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <span>All Clients</span>
              <AntSwitch
                sx={{ mr: 1, ml: 1 }}
                inputProps={{ 'aria-label': 'ant design' }}
                checked={!allClientAgeStats}
                onChange={toggleAllClientAgeStats}
                dual={true}
              />
              <span>In-Detail</span>
            </div>
            {isAdvisorAssetsLoading ? (
              <CircularProgress
                sx={{
                  color: '#479573',
                  position: 'absolute',
                  left: '45%',
                  top: '50%',
                }}
                size={20}
              />
            ) : (
              <ChartContainer
                chartType="spider"
                selectedChart={'ageDistribution'}
                options={ageDistributionOptions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </>
);

const InfoContainer = (cta) => {
  const { textBoxLine1, textBoxLine2, textBoxLine3 } = cta;
  return (
    <div style={{ marginTop: '5px' }}>
      <Typography variant="body2" color="text.secondary">
        {textBoxLine1}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {textBoxLine2}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {textBoxLine3}
      </Typography>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: (state.loggedInUser && state.loggedInUser.userDetails) || {},
    clients: state.clientInfoReducer.clients || [],
    companyAUM: state.clientInfoReducer.companyAUM || {},
    advisorAssetsUnderManagement:
      state.clientInfoReducer.advisorAssetsUnderManagement || {},
    clientsNotUpdated: state.clientInfoReducer.clientsNotUpdated || [],
    clientMeetings: state.clientInfoReducer.clientMeetings || [],
    meetingDetail: state.plansReducer.meetingDetail || {},
    tenure: state.clientInfoReducer.tenure,
    clientTenure: state.clientInfoReducer.clientTenure,
    invMarketExpectationsByAdvisorId:
      state.clientInfoReducer.invMarketExpectationsByAdvisorId || {},
    plans: state.plansReducer.plans || [],
    selectedClientId: selectedClientIdSelector(state),
    selectedPlanId: selectedPlanIdForClientIdSelector(state),
    dashboard: outputResponseDashboardSummaryDetailsByPlanIdSelector(state),
    isOutputResponseLoading: outputResponseLoadingSelector(state),
    isoutputResponseAvailable: isOutputResponseDataAvailable(state),
    planDetailsByPlanId: planDetailsByPlanIdSelector(state),
    isAdvisorStatsLoading: state.clientInfoReducer.isAdvisorStatsLoading,
    isAdvisorAssetsLoading: state.clientInfoReducer.isAdvisorAssetsLoading,
    isClientsNotUpdatedLoading:
      state.clientInfoReducer.isClientsNotUpdatedLoading,
    isClientMeetingsLoading: state.clientInfoReducer.isClientMeetingsLoading,
    allMenuItems: allPlannerMenuItemsSelector(state),
    alltaxSlabs: allTaxSlabsSelector(state),
    plannerDetailsLoading: plannerDetailsLoadingSelector(state),
    simulatePlanDetailsLoading: simulatePlanDetailsLoadingSelector(state),
    selectedChart: selectedChartSelector(state) || '',
    planHighlights: outputResponsePlanHighlightsSelector(state) || {},
    advisorHome: outputResponseAdvisorHomeSelector(state) || {},
    faq: outputResponseFAQsSelector(state) || {},
    goals: outputResponseGoalsSelector(state) || {},
    charts: outputResponseChartsByPlanIdSelector(state) || {},
    planner: outputResponsePlannerDetailsByPlanIdSelector(state) || {},
    chrono: outputResponseChronoDetailsByPlanIdSelector(state) || {},
    selectedNumberOfRuns: getNumberOfRuns(state),
    forecast: state.clientInfoReducer.forecast || [],
    allocation: state.clientInfoReducer.allocation || [],
    selectedAllocationId: state.clientInfoReducer.selectedAllocationId || '',
    selectedForecastId: state.clientInfoReducer.selectedForecastId || '',
    clientDetails: state.plansReducer.clientDetails || {},
    isMobileNumberSaved: state.isMobileNumberSaved || false,
    invAssetAllocations: getInvAssetAllocationsByAdvisorIdSelector(state) || [],
    isInvAssetAllocationsLoading:
      invAssetAllocationsByAdvisorIdLoadingSelector(state),
    invMarketCorrelations:
      getInvMarketCorrelationsByAdvisorIdSelector(state) || [],
    isInvMarketCorrelationsLoading:
      invMarketCorrelationsByAdvisorIdLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    triggerGetPlansByClientId: (payload) =>
      dispatch(getPlansByClientIdAction(payload)),
    triggerGetMeetingsByMeetingId: (payload) =>
      dispatch(getMeetingDetailsByMeetingIdAction(payload)),
    triggerSaveAndUpdateMeetingDetailsbyMeetingId: (payload) =>
      dispatch(saveAndUpdateMeetingDetailsByMeetingIdAction(payload)),
    triggerUpdateMobileNumberByEmailId: (payload) =>
      dispatch(updateUserMobileAction(payload)),
    triggerSetSelectedClientIdAction: (payload) =>
      dispatch(setSelectedClientIdAction(payload)),
    triggersetSelectedPlanIdForClientId: (payload) =>
      dispatch(setSelectedPlanForClientId(payload)),
    triggerGetClientsByAdvisorId: (payload) =>
      dispatch(getClientsByAdvisorIdAction(payload)),
    triggerGetInvMarketExpectationsByAdvisorId: (payload) =>
      dispatch(getInvMarketExpectationsByAdvisorIdAction(payload)),
    triggerGetClientsNotUpdatedForNumDays: (payload) =>
      dispatch(getClientsNotUpdatedForNumDaysAction(payload)),
    triggerGetClientsMeetingsForNumDays: (payload) =>
      dispatch(getClientsMeetingsForNumDaysAction(payload)),
    triggerGetAdvisorAssetsUnderManagement: (payload) =>
      dispatch(getAdvisorAssetsUnderManagementAction(payload)),
    triggerGetAdvisorAssetsUnderManagementCompanyAUM: (payload) =>
      dispatch(getAdvisorAssetsUnderManagementCompanyAUMAction(payload)),
    triggerGetAssetAllocListByClientId: (payload) =>
      dispatch(getAssetAllocListByClientIdAction(payload)),
    triggersetCurrentPlansforClientId: (payload) =>
      dispatch(getPlansByClientIdSuccessAction(payload)),
    triggerSetClientViewSelected: (payload) =>
      dispatch(setClientViewSelected(payload)),
    triggerGetOutputReponseByPlanId: (payload) =>
      dispatch(getOutputResponseByPlanIdAction(payload)),
    triggerClearOutputResponseAction: (payload) =>
      dispatch(clearOutputResponseAction(payload)),
    getSavedSelectedPlanIdDetails: (payload) =>
      dispatch(getPlansDetailsAction(payload)),
    triggersetSelectedDisplayTermForClientId: (payload) =>
      dispatch(setSelectedDisplayTermAction(payload)),
    triggersetSelectedHorizonTermForClientId: (payload) =>
      dispatch(setSelectedHorizonTermAction(payload)),
    triggersetSelectedNumberofRuns: (payload) =>
      dispatch(setSelectedNumberOfRuns(payload)),
    triggerClearPlanDetailsFromStore: (payload) =>
      dispatch(clearPlanDetailsFromStore(payload)),
    clearPushPinCharts: (payload) => dispatch(clearPushPinCharts(payload)),
    resetSimulateBeforeAfterValues: (payload) =>
      dispatch(resetSimulateBeforeAfterValues(payload)),
    setInitialReportOptions: (payload) =>
      dispatch(setInitialReportOptions(payload)),
    triggerSimulatePlanDetailsByPlanId: (payload) =>
      dispatch(simulatePlanDetailsByPlanId(payload)),
    triggerSetTenure: (payload) => dispatch(setTenureAction(payload)),
    triggerSetClientTenure: (payload) =>
      dispatch(setClientTenureAction(payload)),
    triggerSetForecastIdAction: (payload) =>
      dispatch(setForecastIdAction(payload)),
    triggerSetAllocationIdAction: (payload) =>
      dispatch(setAllocationIdAction(payload)),
    triggerSaveAndUpdatePlanDetailsbyPlanId: (payload) =>
      dispatch(saveAndUpdatePlanDetailsByPlanId(payload)),
    triggerGetUserDetailsByEmailId: (payload) =>
      dispatch(getUserInformationByEmailIdAction(payload)),
    triggerUpdatePlannerDetailsinRedux: (payload) =>
      dispatch(simulatePlanDetailsByPlanIdSuccessAction(payload)),
    triggerGetInvAssetAllocationsByAdvisorId: (payload) =>
      dispatch(getInvAssetAllocationsByAdvisorIdAction(payload)),
    triggerGetInvMarketCorrelationsByAdvisorId: (payload) =>
      dispatch(getInvMarketCorrelationsByAdvisorIdAction(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewHome);
